<!--
 * @Author: magic
 * @Date: 2022-03-23 18:21:06
 * @LastEditors: g05047
 * @LastEditTime: 2022-03-25 08:51:34
 * @Description: file content
-->
<template>
  <div class="common-copyright" ref="common-copyright"  :style="'padding:' + data.options.paddingTB + 'px ' + data.options.paddingLF+'px'">
    <div class="common-copyright-box">{{ data.options.content }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import { Button } from "vant";
Vue.use(Button);

export default {
  components: {},
  props: {
    data: null,
    editMode: () => {
      return false;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  watch: {
    data: {
      deep: true,
      handler: function handler() {
        //this.getSize();
      },
    },
  },
};
</script>

<style scoped>
.common-copyright {
  margin: 14px 0px;
}

.common-copyright-box {
  font-size: 12px;
  line-height: 1.2;
  color: #BABABA;
  white-space: pre-wrap;
}

</style>
