<!--
 * @Author: magic
 * @Date: 2021-10-16 20:31:02
 * @LastEditors: g05047
 * @LastEditTime: 2022-03-25 09:28:20
 * @Description: file content
-->
<template>
  <div class="common-box">
    <div class="common-rich-text" @click="onTap" ref="common-rich-text" v-html="data.options.des"
    :style="
        'padding-top:' +
        data.options.paddingTB +
        'px ;padding-left:' +
        data.options.paddingLF +
        'px;padding-right:'+ data.options.paddingLF +'px'
      ">
    </div>
  </div>
</template>

<script>
import { articles_actions } from "@/pages/article/service";
export default {
  components: {},
  props: {
    data: null,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onTap: function (e) {
      if (e.target.nodeName === "LABEL") {
        // 获取触发事件对象的属性
        let msg_id = this.$route.query.msg_id || 1;
        const action_id = this.data.options.behavior

        let type = e.target.getAttribute("data-type");
        let obj_id = e.target.getAttribute("data-id");
        if (type === "article") {
          //埋点上报
          if(action_id) {
            articles_actions({
              msg_id: msg_id,
              action_id: action_id,
              article_id: this.$route.query.id,
              component_type: 1, // 文章
              target_id: obj_id,
            })
          }
          this.$router.push({
            path: "/article",
            query: {
              ...this.$route.query,
              id: obj_id,
            },
          });
        } else {
          articles_actions({
            msg_id: msg_id,
            action_id: action_id,
            article_id: this.$route.query.id,
          })
        }
      }
    },
  },
  watch: {},
};
</script>

<style>
.common-rich-text {
  padding: 10px 14px;
  line-height: 2;
  color: #565656;
  font-size: 16px;
  white-space: pre-wrap;
}
.label-tips {
  color: #008fff;
}

.samp-upper{
  vertical-align: text-bottom;
  font-size: 12px;
}
.common-rich-text div.empty {
  margin: 20px 0px;
}
</style>
