<template>
  <div
    class="hospital-doctor"
    ref="hospital-approved"
    :style="'padding-top:' + data.options.paddingTB + 'px'"
  >
    <template v-if="data.options.mode === '1'">
      <div class="h-d-i-h" v-if="data.options.data.hospital_name">
        {{ data.options.data.hospital_name }}
      </div>
      <div
        class="hospital-room-box"
        @click="onRouterRoom"
        :style="{
          'border-radius': !data.options.data.hospital_name
            ? '8px'
            : '0px 8px 8px 8px',
        }"
      >
        <img class="img1" src="../../../../assets/img/ks.png" />
        <div class="hospital-room-box-info">
          <h1>{{ data.options.data.name }}</h1>
          <p>免排队 在线问诊专家门诊</p>
        </div>
        <!-- <img class="img2" src="../../../../assets/img/img-room-right.png" /> -->
        <div class="room-box-btn">去问诊</div>
      </div>
    </template>
    <template v-else>
      <h3 v-if="data.options.title">
        {{ data.options.title }}
      </h3>
      <div
        class="doctor-box"
        v-if="data.options.list && data.options.list.length > 0"
      >
        <div class="doctor-box-content">
          <div
            v-for="(item, index) in data.options.list"
            :key="'doctor-' + index"
            @click="to_url(item)"
            class="doctor-box-content_"
          >
            <div class="h-d-i-h" v-if="item.info.hospital">
              {{ item.info.hospital }}
            </div>
            <div
              class="hospital-doctor-item"
              :style="{
                'border-radius': !item.info.hospital
                  ? '8px'
                  : '0px 8px 8px 8px',
              }"
            >
              <div class="hospital-doctor-item-header">
                <img :src="doctor_head_img(item.info.headImage)" />
                <div class="center">
                  <div class="hospital-doctor-item-info">
                    <h3>{{ item.info.name }}</h3>
                    <p v-if="item.info.position">{{ item.info.position }}</p>
                  </div>
                  <p class="department_" v-if="item.info.department">
                    {{ item.info.department }}
                  </p>
                </div>
                <div class="hospital-doctor-item-btn">去问诊</div>
              </div>
              <div
                class="hospital-doctor-item-bodyer"
                v-if="item.info.speciality"
              >
                <p class="intro">{{ item.info.speciality }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue"
import { Button } from "vant"
import {
  articles_actions,
  get_at_scheme_link,
  get_at_getToken,
} from "@/pages/article/service"
import { doctor_head_img } from "../../../../utils/imgUrl"
import { setSession_at, getSession_at } from "@/utils/session"
import md5 from "blueimp-md5"
Vue.use(Button)

export default {
  components: {},
  props: {
    data: null,
    editMode: () => {
      return false
    },
  },
  filters: {
    //医生职称:1-主任医师；2-副主任医师；3-主治医师；4-医师;5-住院医师
  },
  data() {
    return {
      userId: "",
    }
  },
  computed: {},
  methods: {
    doctor_head_img,
    onRouterRoom() {
      // 预览不可点
      if (this.$route.query.source === "yunying") return
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 4, // 在线问诊
          department_code: this.data.options.data.code,
          department_name: this.data.options.data.name,
        })
      }
      // 长海
      if ([6, 8].includes(GLOBAL_CONFIG.source)) {
        let redirect = encodeURIComponent(this.data.options.data.url)
        this.$router.push({
          path: "/guide-changhai",
          query: { redirect, msg_id },
        })
        return
      }
      // todo，还没有对接
    },
    async getToken() {
      const username = "hh2022szlh"
      const password = "szlh@2022aop"
      const secretKey = "D9124AE0940FE0C6"
      const sign =
        "u:" + username + ",pw:" + md5(password) + ",key:" + secretKey
      let p = {
        username: username,
        password: md5(password),
        sign: md5(sign),
      }
      const { data } = await get_at_getToken(p)
      if (data.code === 0) {
        setSession_at({ token: data.data })
        this.get_doctor_wx()
      }
    },
    async get_doctor_wx() {
      let params = {
        userId: this.userId,
        actionId: "-999",
        token: "-999",
        version: "release",
      }
      const { data } = await get_at_scheme_link(params)
      console.log(data)
      if (data.data) {
        location.href = data.data
      }
    },
    async to_url(item) {
      // 预览不可点
      if (this.$route.query.source === "yunying") return

      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 4, // 在线问诊
          doctor_code: item?.info?.userId,
          doctor_name: item?.info?.name,
          department_code: item.department_code,
        })
      }
      // 温附一
      if (GLOBAL_CONFIG.source === 3) {
        location.href = `${GLOBAL_CONFIG.wenfuyi_config?.basisHost}/Show/yuyue/doctorpb2/?yhid=${item?.info?.userId}&brbh=${this.$route.query.patient_id}&lx=14&trans_id=${msg_id}`
      }
      // 长海
      else if ([6, 8].includes(GLOBAL_CONFIG.source)) {
        let redirect = encodeURIComponent(item.url)
        this.$router.push({
          path: "/guide-changhai",
          query: { redirect, msg_id },
        })
      }
      // 罗湖
      else if (GLOBAL_CONFIG.source === 2) {
        this.userId = item.info.userId
        const authToken_at = getSession_at()
        if (!authToken_at) {
          this.getToken()
        } else {
          this.get_doctor_wx()
        }
      }
    },
  },
  watch: {},
}
</script>

<style scoped>
.room-box-btn {
  width: 68px;
  height: 28px;
  color: #fff;
  font-weight: bold;
  background: #5bc98e;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  /* font-size: 14px; */
  font-weight: 600;
  font-size: 12px;
}
.department_ {
  font-size: 14px;
  color: #666666;
  margin-top: 6px;
}
.center {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
  flex: 1;
  margin-left: 12px;
}
.hospital-doctor {
  padding: 14px;
  overflow-x: hidden;
  & h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1d2530;
  }
}
.hospital-doctor-item {
  padding: 16px 12px;
  /* border: 1px #eee solid; */
  /* width: 72vw; */
  /* height: calc(270px * 0.6); */
  /* border-radius: 4px; */
  /* margin-right: 3vw; */
  /* float: left; */
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(161, 165, 172, 0.2);
}
.h-d-i-h {
  height: 21px;
  /* line-height: 21px; */
  display: inline-flex;
  align-items: center;
  background: linear-gradient(90deg, #fdf3e6 0%, #fcf0dd 45%, #fcecd5 100%);
  box-shadow: 0px 0px 12px 0px rgba(176, 183, 194, 0.12);
  border-radius: 4px 16px 0px 0px;
  /* display: inline-block; */
  padding: 0 12px;
  font-size: 12px;
  color: #b9875f;
}

.hospital-doctor-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hospital-doctor-item-header img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.doctor-box-content_ + .doctor-box-content_ {
  margin-top: 20px;
}
.doctor-box-content .h-d-i-h {
  margin-bottom: -2px;
}

.hospital-doctor-item-bodyer {
  margin-top: 4px;
  color: #818181;
  margin-left: 52px;
}

.hospital-doctor-item-bodyer p.department {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 2;
  color: #565656;
}

.hospital-doctor-item-bodyer p.intro {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
}

.hospital-doctor-item-btn {
  width: 68px;
  height: 28px;
  color: #fff;
  font-weight: bold;
  background: #2680eb;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  /* font-size: 14px; */
  font-weight: 600;
  font-size: 12px;
}

.hospital-doctor-item-info {
  /* flex: 1; */
  /* padding-left: 10px; */
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  & h3 {
    /* line-height: 1.6; */
    margin: 0px;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  & p {
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    border-left: 1px solid #939aa6;
    padding-left: 4px;
    margin-left: 8px;
    & span {
      background: #0088ff;
      padding: 2px 4px;
      color: #fff;
      border-radius: 4px;
    }
  }
  & .intro {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.4;
  }
}

.doctor-box {
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
  width: 100%;
}

.doctor-box::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.hospital-room-box {
  padding: 16px 12px;
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0px 0px 12px 0px rgba(161, 165, 172, 0.2);
  border-radius: 0px 8px 8px 8px;
  & .hospital-room-box-info {
    flex: 1;
    padding: 0 8px 0 12px;
    & > h1 {
      /* line-height: 1.3; */
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
    & > p {
      padding-top: 5px;
      font-size: 12px;
      color: #666666;
    }
  }
  & .img1 {
    width: 40px;
    height: 40px;
  }
  & .img2 {
    width: 14px;
    height: 14px;
  }
}
</style>
