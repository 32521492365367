import api from '../../../utils/api'
// 评论详情
export async function get_comments (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data:{
  //         total_num1: 100,
  //         total_num2: 200,
  //         title: '文章名称',
  //         total_elements: 2,
  //         data: [
  //           {
  //             id: 1,
  //             article_id: 1,
  //             content: '内容一德国队获得更好的多好的更好的更好的更好的电话都过得更好的更好的过 更好的更好的更好的过的好感动好感动好的过好感动好感动好的更好的过dodged',
  //             from_uid: '11',
  //             to_uid: '22',
  //             parent_id: 1,
  //             parent_name: '张三',
  //             create_time: new Date('2022-10-11'),
  //             type: 1,
  //             featured: true,
  //             reply_list: [
  //               {
  //                 id: '1-1',
  //                 article_id: 1,
  //                 content: '内容一',
  //                 from_uid: '1-11',
  //                 from_name: '李一',
  //                 parent_id: '1-1',
  //                 create_time: new Date(),
  //                 type: 1,
  //                 featured: false,
  //                 role: 1,
  //               },
  //               {
  //                 id: '1-2',
  //                 article_id: 1,
  //                 content: '内容一',
  //                 from_uid: '1-11',
  //                 from_name: '李二',
  //                 to_uid: '1-22',
  //                 to_name: '李一',
  //                 parent_id: '1-1',
  //                 create_time: new Date(),
  //                 type: 2,
  //                 featured: false,
  //                 role: 2,
  //               },
  //               {
  //                 id: '1-3',
  //                 article_id: 1,
  //                 content: '内容一',
  //                 from_uid: '1-11',
  //                 from_name: '李二',
  //                 to_uid: '1-22',
  //                 to_name: '李一',
  //                 parent_id: '1-1',
  //                 create_time: new Date(),
  //                 type: 2,
  //                 featured: false,
  //                 role: 2,
  //               },
  //               {
  //                 id: '1-4',
  //                 article_id: 1,
  //                 content: '内容一',
  //                 from_uid: '1-11',
  //                 from_name: '李二',
  //                 to_uid: '1-22',
  //                 to_name: '李一',
  //                 parent_id: '1-1',
  //                 create_time: new Date(),
  //                 type: 2,
  //                 featured: false,
  //                 role: 2,
  //               }
  //             ]
  //           }
  //         ]

  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/operation/comments/${payload.id}`, { params:payload })
}

// 创建评论
export async function submit_comment (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/operation/comments`, payload)
}

export async function main_info (payload) {
  return api.get(`/api/v1/mp/main`, payload)
}

// 创建评论
export async function submit_read (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/operation/comments/has_read/${payload.id}`,payload)
}
