<template>
  <div
    class="health-log"
    ref="hospital-approved"
    :style="'padding-top:' + data.options.paddingTB + 'px'"
  >
    <p class="health-title" v-if="data.options.title">
      {{ data.options.title }}
    </p>
    <p class="health-sub" v-if="data.options.sub">
      {{ data.options.sub }}
    </p>
    <p class="jump_btn" @click.stop="jump">点击查看 ></p>
  </div>
</template>

<script>
import { get_log_jump_url } from "../../service"
export default {
  components: {},
  props: {
    data: null,
    editMode: () => {
      return false
    },
  },
  filters: {
    //医生职称:1-主任医师；2-副主任医师；3-主治医师；4-医师;5-住院医师
  },
  data() {
    return {
      param: "",
    }
  },
  computed: {},
  created() {
    this.get_url()
  },
  methods: {
    async get_url() {
      const { data } = await get_log_jump_url()
      this.param = data.data
    },
    jump() {
      if (this.param) {
        window.open(
          window.GLOBAL_CONFIG?.luohu_url?.basisHost +
            "/unicom-cloud-medical-view/#/home/medical-record?data=" +
            this.param,
          "_blank"
        )
      } else {
        this.$notify({
          type: "warning",
          message: "暂无日志地址",
        })
      }
    },
  },
  watch: {},
}
</script>

<style scoped>
.health-log {
  padding: 15px;
  overflow-x: hidden;
  background: url("../../../../assets/img/health-log-bg.png") no-repeat;
  background-size: cover;
}

.health-title {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 18px;
  color: #242938;
}

.health-sub {
  padding-top: 3px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 18px;
  color: #242938;
}

.jump_btn {
  padding-top: 11px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: #1890ff;
}
</style>
