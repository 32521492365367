<template>
  <div
    class="common-img"
    ref="commonImg"
    :style="
      'margin:' + data.options.paddingTB + 'px ' + data.options.paddingLF + 'px'
    "
  >
    <a
      :href="data.options.link"
      v-if="data.options.type !== 'no-link'"
      @click="onTap()"
    >
      <img :src="imgUrl" v-if="showImg" class="swipe-imgs" />
    </a>
    <div v-else>
      <img :src="imgUrl" v-if="showImg" class="swipe-imgs" />
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { Button } from "vant"
import { articles_actions } from "@/pages/article/service"
Vue.use(Button)

export default {
  components: {},
  props: {
    data: null,
  },
  data() {
    return {
      offsetWidth: 0,
    }
  },
  computed: {
    showImg() {
      return (
        this.data.options.url !== "" ||
        window.GLOBAL_CONFIG.articleMediaHost !== ""
      )
    },
    imgUrl() {
      return `${window.GLOBAL_CONFIG.articleMediaHost}/${this.data.options.url}`
    },
  },
  methods: {
    onTap() {
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
        })
      }
    },
  },

  watch: {},
}
</script>

<style scoped>
.swipe-imgs {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.swipeBox__empty {
  font-size: 20px;
  color: #ccc;
  background: #fafafa;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
