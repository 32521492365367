<template>
  <div
    v-if="doctor_data.register_doctor_code"
    class="hospital-doctor"
    :style="'padding-top:' + data.options.paddingTB + 'px'"
  >
    <h3 v-if="data.options.title">
      {{ data.options.title }}
    </h3>
    <div class="doctor-box">
      <div class="doctor-box-content" :style="'width:' + 75 + 'vw'">
        <div class="hospital-doctor-item" @click="to_url(doctor_data)">
          <div class="hospital-doctor-item-header">
            <img :src="doctor_head_img(doctor_data.register_image_url)" />
            <div class="hospital-doctor-item-info">
              <h3>{{ doctor_data.register_doctor_name }}</h3>
              <p>{{ doctor_data.register_title }}</p>
            </div>
            <div class="hospital-doctor-item-btn">向TA挂号</div>
          </div>
          <div class="hospital-doctor-item-bodyer">
            <p class="department">{{ doctor_data.register_department_name }}</p>
            <p class="intro">{{ doctor_data.register_desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { Button } from "vant"
import { articles_actions, get_doctors_again } from "@/pages/article/service"
import { doctor_head_img } from "../../../../utils/imgUrl"
Vue.use(Button)

export default {
  components: {},
  props: {
    data: null,
    editMode: () => {
      return false
    },
  },
  filters: {
    //医生职称:1-主任医师；2-副主任医师；3-主治医师；4-医师;5-住院医师
  },
  data() {
    return {
      doctor_data: {},
    }
  },
  computed: {},
  created() {
    this.get_doctors_again()
  },
  methods: {
    doctor_head_img,
    async get_doctors_again() {
      const msg_id = this.$route.query.msg_id
      if (!msg_id) return
      const { data } = await get_doctors_again({
        msg_id,
      })
      this.doctor_data = data
    },
    to_url(item) {
      // 预览不可点
      if (this.$route.query.source === "yunying") return
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 8, // 在线挂号回诊
          doctor_code: item.register_doctor_code,
          doctor_name: item.register_doctor_name,
          department_code: item.register_department_code,
        })
      }
      // 温附一
      if (GLOBAL_CONFIG.source === 3) {
        location.href = `${GLOBAL_CONFIG.wenfuyi_config?.basisHost}/Show/yuyue/doctorpb2/?yhid=${item.register_doctor_code}&brbh=${this.$route.query.patient_id}&lx=0&trans_id=${msg_id}`
      } else if (GLOBAL_CONFIG.source === 5) {
        //厦大中山
        location.href = `${GLOBAL_CONFIG.xiadazhongshang_config?.basisHost}/routeinteface/enterDoctorTime.htm?doctorId=${item.code}`
      } else if (item.s_url || item.url) {
        location.href = item.s_url || item.url
      } else {
        this.$router.push({
          path: "/doctor-page",
          query: {
            hospital_id: item.register_hospital_id,
            department_code: item.register_department_code,
            department_name: item.register_department_name,
            doctor_id: item.register_doctor_code,
          },
        })
      }
    },
  },
  watch: {},
}
</script>

<style scoped>
.hospital-doctor {
  padding: 14px;
  overflow-x: hidden;
  & h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1d2530;
  }
}
.hospital-doctor-item {
  padding: 14px;
  border: 1px #eee solid;
  width: 72vw;
  height: calc(270px * 0.6);
  border-radius: 4px;
  margin-right: 3vw;
  float: left;
}
.hospital-doctor-item-info {
  flex: 1;
  padding-left: 10px;
  font-size: 14px;
  & h3 {
    line-height: 1.2;
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
  }
  & p {
    margin: 4px 0px;
    font-size: 14px;
    color: #565656;
    & span {
      background: #0088ff;
      padding: 2px 4px;
      color: #fff;
      border-radius: 4px;
    }
  }
  & .intro {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.4;
  }
}

.doctor-box {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.doctor-box::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.doctor-box-content {
  width: 1200px;
  margin-bottom: 10px;
}

.hospital-doctor-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hospital-doctor-item-header img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.hospital-doctor-item-bodyer {
  margin-top: 10px;
  color: #818181;
}

.hospital-doctor-item-bodyer p.department {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 2;
  color: #565656;
}

.hospital-doctor-item-bodyer p.intro {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.4;
}

.hospital-doctor-item-btn {
  width: 90px;
  height: 30px;
  color: #fff;
  font-weight: bold;
  background: #2680eb;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  font-size: 14px;
}

.hospital-doctor-item-info {
  flex: 1;
  padding-left: 10px;
  font-size: 14px;
  & h3 {
    line-height: 1.6;
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  & p {
    margin: 4px 0px;
    font-size: 14px;
    color: #565656;
    & span {
      background: #0088ff;
      padding: 2px 4px;
      color: #fff;
      border-radius: 4px;
    }
  }
  & .intro {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.4;
  }
}
</style>
