<template>
  <div class="common-box">
    <div
      class="common-article"
      ref="common-article"
      :style="
        'padding-top:' +
        data.options.paddingTB +
        'px ;padding-left:' +
        data.options.paddingLF +
        'px;padding-right:' +
        data.options.paddingLF +
        'px'
      "
    >
      <div class="common-article-title" v-if="data.options.title != ''">
        {{ data.options.title }}
        <span class="more" @click="toMore" v-if="data.options.type === 2">
          查看更多 <van-icon name="arrow" />
        </span>
      </div>
      <div class="common-video-list" v-if="data.options.type === 2">
        <div class="video-wrapper" v-if="list.length === 0">
          <div class="common-video-list-item">
            <div class="cover_wrapper">
              <img
                class="cover"
                src="../../../../assets/img/article/video-def.png"
                alt=""
              />
              <div class="info">
                <div class="left">
                  <img src="../../../../assets/img/article/play.png" alt="" />
                  <span>212</span>
                </div>
                <div class="right">30:22</div>
              </div>
            </div>
            <div class="video-title">日常化生活中那些东西有毒害的科普视频</div>
          </div>
          <div class="common-video-list-item">
            <div class="cover_wrapper">
              <img
                class="cover"
                src="../../../../assets/img/article/video-def.png"
                alt=""
              />
              <div class="info">
                <div class="left">
                  <img src="../../../../assets/img/article/play.png" alt="" />
                  <span>212</span>
                </div>
                <div class="right">30:22</div>
              </div>
            </div>
            <div class="video-title">日常化生活中那些东西有毒害的科普视频</div>
          </div>
        </div>
        <div class="video-wrapper" v-if="list.length !== 0">
          <div
            class="common-video-list-item"
            @click="toPlay(el)"
            v-for="el in list"
            :key="el.id"
          >
            <div class="cover_wrapper">
              <img
                class="cover"
                src="../../../../assets/img/article/video-def.png"
                alt=""
                v-if="!el.cover"
              />
              <div
                class="video-cover"
                :style="{
                  backgroundImage: `url(${articleMediaHost}/${el.cover})`,
                }"
                v-else
              />
              <div class="info">
                <div class="left">
                  <img src="../../../../assets/img/article/play.png" alt="" />
                  <span>{{ toLocaleString(el.play_count) }}</span>
                </div>
                <div class="right">{{ format_duration(el.times) }}</div>
              </div>
            </div>
            <div class="video-title">
              <span>
                {{ el.describe }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="common-article-list" v-else>
        <template v-if="data.options.list.length === 0">
          <div class="common-article-list-item">
            <div class="common-article-list-item-info">
              <h3>疣是什么？如何检测？</h3>
              <p>疣是皮肤上的一个肿块，是由于病毒侵入皮肤细胞，使…</p>
            </div>
            <img
              src="@/assets/img/img-article-nodata@3x.png"
              class="common-article-list-item-img"
            />
          </div>
          <div class="common-article-list-item">
            <div class="common-article-list-item-info">
              <h3>疣是什么？如何检测？</h3>
              <p>疣是皮肤上的一个肿块，是由于病毒侵入皮肤细胞，使…</p>
            </div>
            <img
              src="@/assets/img/img-article-nodata@3x.png"
              class="common-article-list-item-img"
            />
          </div>

          <div class="common-article-list-item">
            <div class="common-article-list-item-info">
              <h3>疣是什么？如何检测？</h3>
              <p>疣是皮肤上的一个肿块，是由于病毒侵入皮肤细胞，使…</p>
            </div>
            <img
              src="@/assets/img/img-article-nodata@3x.png"
              class="common-article-list-item-img"
            />
          </div>
        </template>
        <template v-else>
          <div
            class="common-article-list-item"
            v-for="(item, index) in list"
            :key="'article-' + index"
            @click="onSel(index)"
          >
            <div class="common-article-list-item-info">
              <h3>{{ item.title }}</h3>
              <p>{{ item.desc || "无描述" }}</p>
            </div>
            <img
              v-if="item.url && item.url !== ''"
              :src="item.host + '/' + item.url"
              class="common-article-list-item-img"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { Button } from "vant"
import {
  articles_actions,
  get_simple_article,
  get_video_list,
} from "@/pages/article/service"
import { format_duration } from "@/utils/format"

Vue.use(Button)

export default {
  components: {},
  props: {
    data: null,
    editMode: () => {
      return false
    },
  },

  data() {
    return {
      list: [],
      articleMediaHost: GLOBAL_CONFIG.articleMediaHost,
    }
  },
  computed: {},
  watch: {
    data(v) {
      this.get_list(v.options.list)
    },
  },
  created() {
    this.get_list(this.data.options.list)
  },
  methods: {
    format_duration,
    toMore() {
      this.$router.push("/video-list")
    },
    toLocaleString(num = 0) {
      if (!num) return 0
      if (num >= 10000) {
        return +(num / 10000).toFixed(1) + "万"
      }
      return num.toLocaleString()
    },
    toPlay(row) {
      // 埋点
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        if (action_id) {
          articles_actions({
            msg_id: msg_id,
            action_id: action_id,
            article_id: this.$route.query.id,
            component_type: 1, // 文章
            target_id: row.id,
          })
        }
      }

      this.$router.push({
        path: "/video",
        query: {
          ...this.$route.query,
          video_no: row.video_no,
        },
      })
    },
    onSel(index) {
      let data = this.data.options.list[index]
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        if (action_id) {
          articles_actions({
            msg_id: msg_id,
            action_id: action_id,
            article_id: this.$route.query.id,
            component_type: 1, // 文章
            target_id: data.id,
          })
        }
      }
      //alert(JSON.stringify(data))
      this.$router.push({
        path: "/article",
        query: {
          ...this.$route.query,
          id: data.id,
        },
      })

      document.getElementById("a_box").scrollTo(0, 0)
    },
    get_list(item) {
      if (this.data.options.type === 2) {
        this.data.options.list?.length &&
          this.get_video_list(this.data.options.list.map((el) => el.video_no))
      } else {
        if (item && item.length) {
          this.list = []
          const item_arr = JSON.parse(JSON.stringify(item))
          this.get_detail(item_arr)
        }
      }
    },
    async get_video_list(ids) {
      const { data } = await get_video_list({
        video_no_list: ids,
        page: 0,
        page_size: 999,
      })
      this.list = data.data
    },
    async get_detail(arr) {
      if (!arr.length) {
        return
      }
      const { data } = await get_simple_article(arr[0].id, {
        encry: GLOBAL_CONFIG.article_encry,
      })
      this.list.push(data)
      arr.splice(0, 1)
      this.get_detail(arr)
    },
  },
}
</script>

<style lang='less'>
.common-video-list {
  margin-top: 20px;
  .video-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .common-video-list-item {
      width: calc((100% - 12px) / 2);
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
    }
    .video-title {
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      margin-top: 8px;
      flex: 1;
      overflow: hidden;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
    }
    .cover_wrapper {
      position: relative;

      box-shadow: 0px 0px 8px 0px rgba(179, 186, 196, 0.07);
      border-radius: 4px;
      height: 112px;
      overflow: hidden;

      .cover {
        position: absolute;
        width: 86px;
        height: 86px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .video-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .info {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(47, 47, 47, 0) 78%,
          rgba(54, 54, 54, 0.29) 88%,
          rgba(0, 0, 0, 0.38) 100%
        );
        position: absolute;
        bottom: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        color: #fff;
        font-size: 10px;
        padding: 0 10px;
        padding-top: 100px;
        padding-bottom: 4px;

        img {
          width: 12px;
          margin-right: 4px;
        }
      }
    }
    .left {
      display: flex;
      align-items: center;
    }
  }
}

.common-box .common-article-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-bottom: 1px #eee solid;
}

.common-article-list-item-img {
  width: 80px;
  height: calc(80px * 0.75);
  margin-left: 10px;
  object-fit: cover;
}

.common-article-title {
  font-size: 20px;
  line-height: 1.2;
  z-index: 3;
  font-weight: 600;
  color: #1d2530;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .more {
    font-size: 12px;
    color: #939aa6;
    display: flex;
    align-items: center;
    font-weight: 400;
  }
}

.common-article-list-item-info {
  flex: 1;
  overflow: hidden;
}

.common-article-list-item-info h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #1d2530;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #1890ff;
}

.common-article-list-item-info p {
  font-size: 14px;
  color: #818181;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.common-rich-text div {
  margin-bottom: 20px;
}
</style>
