<!--
 * @Author: magic
 * @Date: 2021-10-16 20:33:34
 * @LastEditors: g05047
 * @LastEditTime: 2021-10-17 16:16:48
 * @Description: file content
-->
<template>
  <div class="form-radio" ref="form-radio">
    <div
      class="form-radio-bodyer"
      v-for="(item, index) in data.options.list"
      :key="'item_' + index"
    >
      <h3 class="">{{ item.title }}</h3>
      <div
        :class="
          itx.sel ? 'form-radio-item form-radio-item-curr' : 'form-radio-item'
        "
        v-for="(itx, idx) in item.items"
        :key="'form-radio-item_' + idx"
        @click="onTapSel(idx, index)"
      >
        {{ itx.label }}
      </div>
    </div>
    <van-button
      color="red"
      type="primary"
      size="small"
      @click="onConfirm"
      :disabled="!isSelected"
      >提交</van-button
    >
  </div>
</template>

<script>
import { post_readed_tags } from "@/pages/article/service";

export default {
  components: {},
  props: {
    data: null,
    selList: [],
  },
  filters: {},
  data() {
    return {
      isSelected: false,
      postData: undefined,
      msg_id: undefined,
    };
  },
  created() {
    this.msg_id = this.$route.query.msg_id || 1;
  },
  computed: {},
  methods: {
    async onConfirm() {
      for (let v of this.data.options.list) {
        for (let d of v.items) {
          if (d.sel) {
            this.postData = d;
            break;
          }
        }
      }
      if (!this.isSelected) {
        alert("请先选择问题");
      }
      let obj = {
        msg_id: this.msg_id,
        add: this.postData.ds.tag_add_label,
        remove: this.postData.ds.tag_remove_label,
      };
      await post_readed_tags(obj);
      this.$notify({ type: "success", message: "提交成功" })
      //alert("提交成功");
    },
    onTapSel(idx, index) {
      for (let v of this.data.options.list[index].items) {
        v.sel = false;
      }
      console.log(JSON.stringify(this.data.options.list));
      this.data.options.list[index].items[idx].sel = true;
      console.log(JSON.stringify(this.data.options.list));
      this.isSelected = true;
      this.$forceUpdate();
    },
  },
  watch: {},
};
</script>

<style scoped>
.form-radio {
  padding: 14px 14px;
}
.form-radio-header p {
  margin: 0px;
  font-size: 14px;
  color: #565656;
  line-height: 1.4;
}
.form-radio-bodyer {
  margin: 10px 0px;
}

.form-radio-bodyer h3 {
  font-size: 16px;
  color: #222222;
  margin: 0px;
  font-weight: 500;
  line-height: 2;
}

.form-radio-item {
  width: 100%;
  border-radius: 4px;
  padding: 10px 10px;
  border: 1px #eee solid;
  font-size: 16px;
  margin-bottom: 10px;
  color: #777;
}
.form-radio-item-curr {
  background: #1890ff;
  color: #fff;
}
</style>
