<template>
  <div
    :class="['patient_notice', {slide_down: !hide}, {slide_up: hide}]">
    <img src="../../assets/img/article/notice.png" class="img" />
    <div class="content">
      <h3>智慧服务对象：{{ patient_name }}</h3>
      <p>温馨提示：如您已绑定多张就诊卡，智慧服务对象可能为非本人，请核实信息</p>
    </div>
    <van-icon name="cross close" @click="close"/>
  </div>
</template>
<script>
import { get_patient_info } from './service';
export default {
  props:{
    msg_id: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      patient_name: '',
      hide: false,
      timer: null,
    }
  },
  components: {
  },
  watch: {
    msg_id() {
      this.init_data()
    }
  },
  created (){
    this.init_data()
    this.timer = setTimeout(() => {
      this.close()
    }, 7000)
  },
  methods: {
    async init_data () {
      const { data } = await get_patient_info({msg_id: this.msg_id})
      this.patient_name = data.patient_name
    },
    close () {
      if (this.timer) clearTimeout(this.timer)
      this.hide = true
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.patient_notice {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 6px 10px 1px rgba(0,0,0,0.16);
  border-radius: 10px;
  width: 94%;
  position: fixed;
  top: 0px;
  left: 3%;
  z-index: 999;
  padding: 12px;
  & .img {
    width: 40px;
    height: 40px;
    margin-right: 13px;
  }
  & .content {
    flex: 1;
    & h3 {
      font-size: 15px;
      font-weight: 500;
      color: #1E252F;
      line-height: 21px;
    }
    & p {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      margin-top: 4px;
    }
  }
  & .close {
    color: #979797;
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 16px;
  }
}

/* 动画 */
@keyframes slide_up {
  to {
    opacity: 0;
    transform: translate(0, -100%);
  }
}
@keyframes slide_down {
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.slide_up {
  animation: slide_up 1s ease forwards;
}
.slide_down {
  opacity: 0;
  transform: translate(0, -100%);
  animation: slide_down 1s ease forwards 2s;
}
</style>
