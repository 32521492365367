<template>
  <div class="common-box">
    <div class="common-title" ref="common-title">
      <h1>{{ data.options.title }}</h1>
    </div>
    <div class="common-ai">
      <!-- <img src="@/assets/widget/img-ai-logo.jpg" class="common-ai-logos" /> -->
      <div class="common-ai-info">
        <!-- <h3>智能健康助手</h3>
        <span>全天候守护您的健康</span> -->
         <span><span class="links">{{send_company}}{{data.options.rooms?data.options.rooms:''}}</span></span>
      </div>
      <!-- <div class="btn">提问</div> -->
    </div>
    <div class="common-ai-alert" v-if="data.options.type !== 'none'">
      <div class="common-ai-alert-arrow">
        <div class="common-ai-alert-arrow-block"></div>
      </div>
      <div class="common-ai-alert-box">
        <template v-if="data.options.type === 'do-dialog'" style="margin: 0px">
          {{ data.options.content }}
        </template>
        <template
          v-if="
            data.options.type === 'do-check' &&
            examItems === undefined &&
            labsItems === undefined
          "
        >
          <h3>医生要求您检查以下项目</h3>
          <span>你可以点击下面项目名称了解检查意义</span>
          <div class="common-ai-alert-box-item">
            <div
              class="item-header"
              @click="show_code_tips('EXM00082', '胸部磁共振')"
            >
              <div class="info">
                <img src="@/assets/widget/img-check@3x.png" /> 胸部磁共振
              </div>
              <div class="des">检查</div>
            </div>
            <p>检查地点: 罗湖人民医院C楼 2楼超声室</p>
          </div>
          <div class="common-ai-alert-box-item">
            <div
              class="item-header"
              @click="show_labs_tips('798', '甲型肝炎病毒抗体IgM')"
            >
              <div class="info">
                <img src="@/assets/widget/img-check@3x.png" />
                甲型肝炎病毒抗体IgM
              </div>
              <div class="des">检验</div>
            </div>
            <p>检查地点: 罗湖人民医院1楼 门诊1楼抽血处</p>
          </div>
          <div class="common-banner">
            <div class="info">
              <h3>看病为什么要做检查？</h3>
              <p>检查检验看病</p>
            </div>
            <div class="action">
              <div class="circle"></div>
              <div class="btn">查看详情</div>
            </div>
          </div>
        </template>

        <template
          v-if="
            data.options.type === 'do-check' &&
            (event_type === '30' || event_type === '21' || event_type === '40')
          "
        >
          <h3>医生要求您检查以下项目</h3>
          <span>你可以点击下面项目名称了解检查意义</span>
          <div
            class="common-ai-alert-box-item"
            v-for="item of labsItems"
            :key="'labsItems_' + item.id"
            @click="show_code_tips(item.original_code, item.original_name)"
          >
            <div class="item-header">
              <div class="info">
                <!-- <img src="@/assets/widget/img-check@3x.png" />  -->
                {{ item.original_name }}
              </div>
              <div class="des">检验</div>
            </div>
            <!-- <p>{{item.introduction}}</p> -->
          </div>

          <div
            class="common-ai-alert-box-item"
            v-for="item of examItems"
            :key="'examItems_' + item.id"
            @click="show_code_tips(item.original_code, item.original_name)"
          >
            <div class="item-header">
              <div class="info">
                <!-- <img src="@/assets/widget/img-check@3x.png" />  -->
                {{ item.original_name }}
              </div>

            </div>
            <!-- <p>{{item.introduction}}</p> -->
          </div>

          <div class="common-banner">
            <div class="info">
              <h3>看病为什么要做检验检查？</h3>
              <p>检查检验看病</p>
            </div>
            <div class="action">
              <div class="circle"></div>
              <div class="btn">查看详情</div>
            </div>
          </div>
        </template>

        <!-- <template v-if="data.options.type === 'do-check'  && examItems !== undefined">
          <h3>医生要求您检查以下项目</h3>
          <span>你可以点击下面项目名称了解检查意义</span>
          <div class="common-ai-alert-box-item"   v-for="item of examItems" :key="'labsItems_'+item.id"  @click="show_code_tips(item.item_id, item.standard_name)">
            <div
              class="item-header">
              <div class="info">
                <img src="@/assets/widget/img-check@3x.png" /> {{item.standard_name}}
              </div>
              <div class="des">检查</div>
            </div>
            <p>{{item.introduction}}</p>
          </div>

          <div class="common-banner">
            <div class="info">
              <h3>看病为什么要做检查？</h3>
              <p>检查检验看病</p>
            </div>
            <div class="action">
              <div class="circle"></div>
              <div class="btn">查看详情</div>
            </div>
          </div>
        </template> -->

        <template
          v-if="data.options.type === 'do-reports' && laboratories != undefined"
        >
          <h3>
            {{ laboratories.patient_name }}的
            <samp class="blue">{{ laboratories.report_name }}</samp> 报告如下
          </h3>
          <span>你可以点击下面项目名称了解检查意义</span>
          <div v-if="laboratories && laboratories.records">
            <div
              class="common-ai-alert-box-item"
              v-for="(item, index) of laboratories.records"
              :key="'items' + index"
            >
              <div class="item-header">
                <div
                  class="info"
                  @click="show_name_tips(item.original_laboratory_name)"
                >
                  {{ item.original_laboratory_name }}
                </div>
                <div
                  :class="
                    item.original_abnormal_indicator === 'N' ? 'des' : 'des red'
                  "
                >
                  {{ item.original_abnormal_indicator | filterIndicator }}
                </div>
              </div>
              <p style="margin-top: 0px">
                结果: {{ item.original_result }}<br />
                参考值: {{ item.original_reference }} 标本:
                {{ item.original_specimen }}
              </p>
            </div>
          </div>
          <div class="common-banner" style="display: none" @click="onTapReport">
            <div class="info">
              <h3>看不懂报告？指标有异常？</h3>
              <p>试试AI智能报告解读</p>
            </div>
            <div class="action">
              <div class="circle"></div>
              <div class="btn">查看详情</div>
            </div>
          </div>
        </template>

        <template
          v-if="data.options.type === 'do-reports' && examines != undefined"
        >
          <h3>{{ examines.patient_name }}的检查报告如下</h3>
          <span>你可以点击下面项目名称了解检查意义</span>
          <div
            class="common-ai-alert-box-item"
            v-for="(item, index) of examines.items"
            :key="'items' + index"
          >
            <div class="item-header" @click="show_name_tips(item.name)">
              <div class="info">
                <img src="@/assets/widget/img-check@3x.png" /> {{ item.name }}
              </div>
              <!-- <div class="des">检查</div> -->
            </div>
            <p>
              {{ item.summary }}
            </p>
            <!-- <div class="btn">查看详情</div> -->
          </div>

          <div class="common-banner" style="display: none" @click="onTapReport">
            <div class="info">
              <h3>看不懂报告？指标有异常？</h3>
              <p>试试AI智能报告解读</p>
            </div>
            <div class="action">
              <div class="circle"></div>
              <div class="btn">查看详情</div>
            </div>
          </div>
        </template>

        <template
          v-if="data.options.type === 'do-medicines' && medicines !== undefined"
        >
          <h3>建议您按照以下方案进行服药哦</h3>
          <span>AI助手会在每天服药时间提醒您</span>
          <div
            v-for="(item, index) in medicines.prescriptions"
            :key="'medicines' + index"
          >
            <div
              class="common-ai-alert-box-item"
              v-for="(obj, index) in item.medicines"
              :key="'medicines' + index"
            >
              <div
                class="item-header"
                @click="
                  show_labs_tips(
                    obj.medi_code,
                    obj.original_medi_name.replace(`[${obj.standard_spec}]`, '')
                  )
                "
              >
                <div class="info">{{ obj.original_medi_name }}</div>
              </div>
              <p class="content">
                数量: {{ obj.show_quantity }} {{ obj.standard_show_unit }}<br />
                用法: {{ obj.frequency_name }} 每次{{ obj.usage }}
              </p>
            </div>
          </div>
        </template>
        <template
          v-if="data.options.type === 'do-medicines' && medicines == undefined"
        >
          <h3>建议您按照以下方案进行服药哦</h3>
          <span>AI助手会在每天服药时间提醒您</span>
          <div class="common-ai-alert-box-item">
            <div
              class="item-header"
              @click="show_labs_tips('798', '头孢拉丁缓释片')"
            >
              <div class="info">头孢拉丁缓释片0.5mg*20</div>
              <div class="des">6盒</div>
            </div>
            <p class="content">用法每日二次 一次0.5mg/一片</p>
          </div>

          <div class="common-ai-alert-box-item">
            <div
              class="item-header"
              @click="show_labs_tips('798', '头孢拉丁缓释片')"
            >
              <div class="info">头孢拉丁缓释片0.5mg*20</div>
              <div class="des">6盒</div>
            </div>
            <p class="content">用法每日二次 一次0.5mg/一片</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_event_prescriptions_reports,
  get_laboratories_reports,
  get_laboratories_reports_detail,
  get_examines_reports,
  order_group_datail,
} from "@/pages/article/service";
import { format_date } from "@/utils/format";
export default {
  components: {},
  props: {
    data: null,
    date: null,
  },
  created() {
    this.event_type = this.$route.query.event_type || "";
    this.target_id = this.$route.query.target_id || "";

    //处方
    this.loadData();
  },
  data() {
    return {
      event_type: "",
      target_id: "",
      medicines: undefined,
      laboratories: undefined,
      examines: undefined,
      labsItems: undefined,
      examItems: undefined,
      send_company: GLOBAL_CONFIG.hospital_sign,
    };
  },
  filters: {
    filterIndicator(title) {
      switch (title) {
      case "L":
        return "偏低";
      case "N":
        return "正常";
      case "H":
        return "偏高";
      default:
        return "";
      }
    },
    filterDate(send_time) {
      return format_date(send_time, "yyyy-MM-dd HH:mm");
    },
  },

  computed: {},
  methods: {
    onTapReport() {
      this.$router.push({
        path: "/report",
        query: { record_id: this.laboratories.record_id },
      });
    },
    async loadData() {
      //获取处方信息
      if (
        this.data?.options?.type === "do-medicines" &&
        this.event_type === "80"
      ) {
        //获取处方信息
        const { data } = await get_event_prescriptions_reports(this.target_id);
        if (data) {
          this.medicines = data;
        }
        return;
      }

      let obj;

      if (
        this.data?.options?.type === "do-reports" &&
        this.event_type === "41"
      ) {
        //获取检验信息
        // const { data } = await get_laboratories_reports_detail(this.target_id);
        const { data } = await get_laboratories_reports({report_id: this.target_id});
        if (data) {
          //alert(data);
          obj = data;
        }
        let laboratories = await get_laboratories_reports_detail(
          this.target_id
        );
        if (laboratories) {
          //alert(data);
          obj.records = laboratories.data;
        }
        this.laboratories = obj;
      }

      if (this.data?.options?.type === "do-check" && this.event_type === "21") {
        //获取处方信息

        const { data } = await order_group_datail(this.target_id);
        if (data) {
          this.examItems = data.examines_list;
        }
      }

      if (
        this.data?.options?.type === "do-check" &&
        (this.event_type === "40" || this.event_type === "30")
      ) {
        //获取检查信息

        const { data } = await order_group_datail(this.target_id);
        if (data) {
          // alert(JSON.stringify(data.examines_list));
          //  alert(JSON.stringify(data.laboratories_list));
          this.examItems = data.examines_list;
          this.labsItems = data.laboratories_list;
          // for(let v of list){
          //   if(v.order_type===2){
          //     //检查
          //     this.examItems = v.items
          //   }
          // }
        }
      }

      //获取检查报告
      // if (this.data?.options?.type == "do-reports" && this.event_type == "41") {
      //   //获取检验信息
      //   // const { data } = await get_laboratories_reports_detail(this.target_id);
      //   const { data } = await get_laboratories_reports(this.target_id);
      //   if (data) {
      //     //alert(data);
      //     this.medicines = data;
      //   }
      // }

      if (
        this.data?.options?.type === "do-reports" &&
        this.event_type === "22"
      ) {

        //获取检查信息
        // const { data } = await get_laboratories_reports_detail(this.target_id);
        const { data } = await get_examines_reports(this.target_id);
        if (data) {
          // alert(data);
          this.examines = data;
        }
      }
    },
    show_code_tips(id, name) {
      this.$emit("EVENT_CODE_TIPS", { id: id, name: name });
    },
    show_name_tips(name) {
      this.$emit("EVENT_NAMES_TIPS", { name: name });
    },
  },
  watch: {},
};
</script>

<style scoped >
.common-box {
  padding: 14px 14px;
  margin-top:40px;
}

.common-ai {
  display: flex;
  align-items: center;
  justify-content: space-between;
   padding-bottom: 20px;
  border-bottom: 1px #eee solid;
}

.common-ai .common-box {
  padding: 0px 14px;
}

.common-ai .common-ai {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.common-ai .common-ai-logos {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px #f2f2f2 solid;
}

.common-ai .common-ai .btn {
  width: 60px;
  height: 24px;
  background: #008fff;
  color: #fff;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
}
.common-ai .common-ai-info {
  flex: 1;

}

.common-ai .common-ai-info  .links{
    color: #008fff
}

.common-ai .common-ai-info h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  line-height: 1.6;
}
.common-ai .common-ai-info span {
  font-size: 14px;
  margin: 0;
  color: #818181;
  line-height: 1.6;
}
.common-ai .common-ai-alert-arrow-block {
  margin-left: 20px;
  width: 0;
  height: 0;
  border-radius: 8%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f2f7ff;
}
.common-ai .common-ai-alert-box {
  color: #565656;
  padding: 14px;
  background: #f2f7ff;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 10px;
}
.common-ai .common-ai-alert-box h3 {
  font-size: 16px;
  color: #222222;
  line-height: 1.6;
  margin: 0px;
  font-weight: 500;
}

samp.blue {
  color: #1890ff;
}
.common-ai .common-ai-alert-box span {
  margin-bottom: 30px;
  display: block;
  font-size: 14px;

}
.common-ai-alert-box-item {
  margin-top: 14px;
  border-bottom: 1px #e6e6e6 solid;
  padding-bottom: 14px;
  &:last-child {
    border-bottom: 0px;
  }
}

.common-ai .common-ai-alert-box-item .item-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.common-ai .common-ai-alert-box-item .item-header .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1890ff;
  font-weight: 600;

  font-size: 16px;
}
.common-ai .common-ai-alert-box-item .item-header .info img {
  width: 18px;
  margin-right: 10px;
}
.common-ai .common-ai-alert-box-item .item-header .des {
  font-size: 14px;
}
.common-ai .common-ai-alert-box-item p {
  margin: 0px;
  line-height: 1.6;
  margin-top: 10px;
}
.common-ai .common-ai-alert-box-item .btn {
  width: 74px;
  height: 24px;
  background: #fff;
  border: 1px #008fff solid;
  color: #008fff;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 10px;
}
.common-ai .common-banner {
  background: #eaf7ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.common-ai .common-banner .info {
  padding: 20px 6px;
  width: 64%;
}
.common-ai .common-banner .info h3 {
  color: #23474f;
  margin: 0px;
  font-size: 15px;
  line-height: 1.4;
}
.common-ai .common-banner .info p {
  color: #558eaa;
  margin: 0px;
  font-size: 14px;
}
.common-ai .common-banner .action {
  width: 32%;
  overflow: hidden;
  height: 68px;
}
.common-ai .common-banner .action .circle {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: #dff3ff;
  margin-left: 40px;
  margin-top: -10px;
}
.common-ai .common-banner .action .btn {
  position: relative;
  width: 80px;
  height: 24px;
  background: #008fff;
  color: #fff;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
  margin-top: -50px;
  margin-left: 0px;
}
.common-title h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 20px;
}
.common-ai .common-title p a {
  color: #008fff;
}
.common-ai .common-title p {
  font-size: 14px;
  font-weight: 400;
  color: #565656;
  margin: 20px 0px;
}
.common-ai .common-title span {
  color: #565656;
}
.common-ai-logos {
  width: 60px;
}
.common-ai .btn {
  width: 60px;
  height: 24px;
  background: #008fff;
  color: #fff;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
}
.common-ai-info {
  width: 60%;
}
.common-ai-info h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.common-ai-info span {
  font-size: 14px;
  margin: 0;
}
.common-ai-alert-arrow-block {
  margin-left: 20px;
  width: 0;
  height: 0;
  border-radius: 8%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f2f7ff;
}
.common-ai-alert-box {
  color: #565656;
  padding: 14px;
  background: #f2f7ff;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 10px;
  line-height: 1.8;
}
.common-ai-alert-box h3 {
  font-size: 16px;
  color: #222222;
  line-height: 1.6;
  margin: 0px;
  font-weight: 500;
}
.common-ai-alert-box span {
  margin-bottom: 30px;
  display: block;
  font-size: 14px;
}
.common-ai-alert-box-item {
  margin-top: 14px;
  border-bottom: 1px #e6e6e6 solid;
  padding-bottom: 14px;
}
.common-ai-alert-box-item .item-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.common-ai-alert-box-item .item-header .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1890ff;
  font-weight: 600;
  font-size: 16px;
  width: 80%;
}
.common-ai-alert-box-item .item-header .info img {
  width: 18px;
  margin-right: 10px;
}
.common-ai-alert-box-item .item-header .des {
  font-size: 14px;
  width: 40px;
}
.common-ai-alert-box-item p {
  margin: 0px;
  line-height: 1.6;
  margin-top: 10px;
}
.common-ai-alert-box-item .btn {
  width: 74px;
  height: 24px;
  background: #fff;
  border: 1px #008fff solid;
  color: #008fff;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 10px;
}

.common-banner {
  background: #eaf7ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.common-banner .info {
  padding: 20px 6px;
  width: 64%;
}
.common-banner .info h3 {
  color: #23474f;
  margin: 0px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
}
.common-banner .info p {
  color: #558eaa;
  margin: 0px;
  font-size: 14px;
}
.common-banner .action {
  width: 32%;
  overflow: hidden;
  height: 68px;
}
.common-banner .action .circle {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: #dff3ff;
  margin-left: 40px;
  margin-top: -10px;
}
.common-banner .action .btn {
  position: relative;
  width: 80px;
  height: 24px;
  background: #008fff;
  color: #fff;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
  margin-top: -50px;
  margin-left: 0px;
}


.common-title p a {
  color: #008fff;
}

.common-title p {
  font-size: 14px;
  font-weight: 400;
  color: #565656;
  margin: 10px 0px;
}

.common-title span {
  color: #565656;
}
.red {
  color: red;
}
</style>
