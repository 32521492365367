<template>
  <VideoChoose v-if="video_no" :data="data" />
  <VideoUpload v-else :data="data" />
</template>

<script>
import VideoChoose from "./common-video-choose.vue"
import VideoUpload from "./common-video-upload.vue"
export default {
  props: {
    data: null,
  },
  components: {
    VideoChoose,
    VideoUpload,
  },
  computed: {
    video_no() {
      return (
        !!this.data.options.video_no ||
        (!this.data.options.path && !this.data.options.url)
      )
    },
  },
}
</script>

