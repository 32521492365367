<template>
  <div
    class="hospital-call"
    :style="{
      paddingTop: data.options.paddingT + 'px',
      paddingBottom: data.options.paddingB + 'px',
    }"
  >
    <div class="wrapper">
      <div class="hospital-call-title" v-if="data.options.title">
        {{ data.options.title }}
      </div>
      <!-- <div class="hospital-call-sub-title" v-if="data.options.sub">
        {{ data.options.sub }}
      </div> -->
      <!-- v-if="data.options.phoneNum" -->
      <a @click="call" :href="'tel:' + data.options.phoneNum"
        ><van-icon class="icon" name="phone" />点击拨打</a
      >
    </div>
  </div>
</template>

<script>
import { articles_actions } from "@/pages/article/service"

export default {
  props: {
    data: null,
  },
  methods: {
    call() {
      // 预览不可点
      if (this.$route.query.source === "yunying") return
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 9, // 打电话
          phone_no: this.data.options.phoneNum,
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.wrapper {
  height: 44px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(176, 183, 194, 0.22);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  padding: 0 12px;
}
.hospital-call-title {
  flex: 1;
  margin-right: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-weight: 600;
  font-size: 13px;
  color: #55637b;
}
a {
  background: #f6f7fa;
  border-radius: 31px;
  width: 84px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #59606b;
}
.icon {
  margin-right: 6px;
  font-size: 16px;
}
</style>