<template>
  <div
    class="hospital-check"
    ref="hospital-check"
    v-if="data.options.planid !== ''"
    :style="'padding-top:' + data.options.paddingTB + 'px'"
  >
    <div class="hospital-check-box">
      <div class="header">
        <h3>{{ data.options.title }}</h3>
        <p>{{ data.options.des }}</p>
      </div>
      <div class="bodyer">
        <div
          class="item"
          v-if="
            data.options.model.exam_list && data.options.model.exam_list.length
          "
        >
          <img src="@/assets/widget/img-check@3x.png" />
          <div class="info">
            <h3>检查</h3>
            <p
              v-for="(item, index) in data.options.model.exam_list"
              :key="'exam_list_' + index"
              @click="show_exam_tips(item.id, item.name)"
            >
              <span> {{ item.name }}</span>
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            data.options.model.lab_list && data.options.model.lab_list.length
          "
        >
          <img src="@/assets/widget/img-check2@3x.png" />
          <div class="info">
            <h3>检验</h3>
            <p
              v-for="(item, index) in data.options.model.lab_list"
              :key="'lab_list_' + index"
              @click="show_labs_tips(item.id, item.name)"
            >
              <span> {{ item.name }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="action">
        <div class="btn" @click="update_to_page">预约</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_order_id,
  articles_actions,
} from "@/pages/article/service";
export default {
  components: {},
  props: {
    data: null,
  },
  created() {
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  methods: {
    show_exam_tips(id, name) {
      this.$emit("EVENT_CODE_TIPS", { id: id, name: name });
    },
    show_labs_tips(id, name) {
      this.$emit("EVENT_NAMES_TIPS", { id: id, name: name });
    },
    async update_to_page() {
      // 预览不可点
      if (this.$route.query.source === 'yunying') return
      this.$emit('show_loading')
      let payload = {
        examine_ids: [],
        laboratory_ids: [],
      }
      let model_obj = this.data.options.model
      payload.examine_ids = model_obj.exam_list.map(item=>{
        return item.id
      })
      payload.laboratory_ids = model_obj.lab_list.map(item=>{
        return item.id
      })

      try {
        let msg_id = this.$route.query.msg_id || 1;
        if (this.data.options.behavior && this.data.options.behavior !== "") {
          //埋点上报
          const action_id = this.data.options.behavior;
          articles_actions({
            msg_id: msg_id,
            action_id: action_id,
            article_id:
            this.$route.query.id,
            component_type: 6, // 推荐检验检查
            target_id: model_obj.planid,
          });
        }
        const { data } = await get_order_id(payload);
        this.$router.push({
          path: "pre-order",
          query: { order_id: data.order_id },
        });
      } finally {
        this.$emit("hide_loading");
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.hospital-check {
  background: #fff;
}

.hospital-check-box {
  margin: 14px;
  background: #f2f7ff;
  padding: 20px 14px;
  border-radius: 10px;
}

.hospital-check-box .header h3 {
  font-size: 18px;
  color: #1d2530;
  margin: 0px;
  line-height: 1.6;
  font-weight: 600;
}

.hospital-check-box .header p {
  margin: 0px;
  font-size: 14px;
  color: #565656;
  line-height: 1.4;
}

.hospital-check-box .bodyer .item {
  margin: 20px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.hospital-check-box .bodyer .item img {
  width: 20px;
  height: 20px;
}

.hospital-check-box .bodyer .item .info {
  flex: 1;
  margin-left: 10px;
}

.hospital-check-box .bodyer .item .info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.hospital-check-box .bodyer .item .info p {
  margin: 4px 0px;
  font-size: 16px;
  line-height: 1.6;
}

.hospital-check-box .bodyer .item .info p span {
  color: #1890ff;
}

.hospital-check-box .action {
  width: 100%;
}

.hospital-check-box .action .btn {
  margin-left: 30px;
  height: 28px;
  color: #fff;
  width: 70px;
  background: #1890ff;
  padding: 0 10px;
  line-height: 28px;
  text-align: center;
  border-radius: 14px;
}
</style>
