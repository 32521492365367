<template>
  <van-action-sheet
      v-model="show"
      @close="close"
      :title="comment_count + '条评论'"
    >
      <g-loading :loading="loading" />
     <div class="no-data common" v-if="list.length===0&&!loading">
      <img width="185" src="../../../assets/img/order/no-date.png"/>
      暂无评论信息
    </div>
    <div class="g_main_content common" v-else>
      <div class="list">
        <van-list
          :offset="100"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="i in list" :key="i.id" class="parent-item">
            <div class="one">
              <div class="t-img">
                <img v-if="i.from_avatar_url" :src="i.from_avatar_url"/>
                <img v-else src="../../../assets/img/user-header-0.png"/>
              </div>
              <div class="one-name">{{i.from_name}}</div>
            </div>
            <div class="two" slot="reference">{{i.content}}</div>
            <div class="three">{{time_str(i.create_time)}}<img @click="to_comment(i)" width="22" height="22" src="../../../assets/img/comment-info.png" /></div>
            <div v-for="(item,index) in i.reply_list" :key="i.id + '' + index">
              <template v-if="i.show_more">
                <div class="reply">
                  <span v-if="item.from_name">{{item.from_name}}（{{item.from_type === 1 ? '患者' : '医生'}}）</span>
                  <span class="reply-text1" v-if="item.to_name && item.to_cid !== i.id">回复</span>
                  <span class="reply-content"><span v-if="item.to_name && item.to_cid !== i.id">{{item.to_name}}（{{item.to_type === 1 ? '患者' : '医生'}}）</span>：</span>
                  <span>{{item.content}}</span>
                </div>
                <div class="three three1">{{time_str(item.create_time)}}<img @click="to_comment(item)" width="22" height="22" src="../../../assets/img/comment-info.png" /></div>
              </template>
            </div>
            <div class="more" @click="more_operate(i)" v-if="i.reply_list">
              <span v-if="i.show_more">收起回复</span>
              <span v-else>共{{i.reply_list.length}}条回复</span>
              <van-icon  style="margin-left: 6px;" :name="i.show_more ? 'arrow-up': 'arrow-down'"/>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <van-form @submit="onSubmit" class="footer">
      <van-field
        ref="t_comment"
        v-model="content"
        enterkeyhint="send"
        @click="input_click"
        @blur="blur"
        class="my-input"
        name="content"
        :placeholder="current_item.from_uid  ? ('回复' + current_item.from_name + (current_item.from_type === 1 ? '（患者）' : '（医生）')) + '：'  : '为改善服务质量，我们真诚地期待您的反馈'"
        :rules="[{ required: true, message: '' }]"
      >
        <template #button>
          <van-button type="primary" class="bt" @click="bt_click"  size="small">发送</van-button>
        </template>
      </van-field>

    </van-form>
  </van-action-sheet>
</template>
<script>
import {get_comments,submit_comment,main_info,submit_read} from './service'
import {format_date} from '../../../utils/format'
import { mapMutations } from "vuex";
export default {
  data () {
    return {
      list: [],
      finished:false,
      loading: false,
      timer: null,
      page_size: 10,
      page: 0,
      total: 0,
      error: false,
      has_data: false,
      content: '',
      current_item: {},
      current_item_id: '',
      data: {},
      show: false,
      type: 1,//1:写评论 2:回复
      parent_info: {},
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    id:{
      default: '',
      type: String
    },
    comment_count: {
      default: 0,
      type: Number
    }
  },
  computed:{
    noMore () {
      return this.has_data&&(this.total === 0 || (this.list.length >= this.total))
    },

  },
  watch: {
    visible() {
      this.show = this.visible
    },
    content() {

    }
  },
  created() {
    this.onLoad()
    this.show = this.visible
    this.main_info()
  },
  methods: {
    ...mapMutations("no_read", [
      'clearState'
    ]),
    format_date,
    time_str (time) {
      let str = format_date(new Date().getTime(), 'yyyy/MM/dd')
      let now_str = new Date(str).getTime()
      if (time >= now_str) {
        return format_date(time, 'HH:mm')
      } else if (now_str - time <= 24 * 60 * 60 * 1000) {
        return format_date(time, '昨天 HH:mm')
      }
      return format_date(time, 'yyyy-MM-dd HH:mm')
    },
    onLoad() {
      if(this.timer) clearTimeout(this.timer)
      if(this.noMore || this.error) {
        this.loading = false
        this.finished = true
        return
      }
      this.loading = true
      this.timer = setTimeout(()=>{
        this.page = this.page + 1
        this.search_comments()
      },1000)
    },
    onRefresh() {
      this.finished = false
      this.loading = false
      this.has_data = false
      this.error = false
      this.list = []
      this.page = 0
      this.onLoad()
    },
    close() {
      //更新个数
      this.clearState()
      this.$emit('update:visible',false)
    },
    //获取焦点变回复
    to_comment(obj) {
      this.content = ''
      this.current_item = obj
      this.current_item_id = this.current_item.parent_id || this.current_item.id
      this.type = 2
      this.$refs.t_comment.focus()
    },
    //失去焦点清空变为评论
    blur() {
      if(!this.content)this.clear_data()
    },
    clear_data() {
      this.content = ''
      this.current_item = {}
      this.type = 1
    },
    input_click() {
      if(!this.current_item.id)this.current_item_id = ''
    },
    more_operate(val) {
      let no_read_arr = []
      this.list = this.list.map(i=>{
        if(i.id ===val.id) {
          i.show_more = ! i.show_more
          if(i.show_more) {
            for(let item of i.reply_list) {
              if(!item.read&&item.to_uid === this.parent_info.id)no_read_arr.push(item.id)
            }
          }
        }
        return i
      })
      if(no_read_arr.length>0) this.to_submit_read(no_read_arr,val)
    },
    bt_click() {
      if(!this.content){
        this.$refs.t_comment.focus()
      } else {
        this.onSubmit()
      }
    },
    async main_info () {
      const{data} = await main_info()
      this.parent_info = data
    },
    async onSubmit () {
      try{
        this.loading = true
        const {content,id} = this
        let {article_id,from_uid,parent_id,from_type} = this.current_item
        let params = {
          content,
          article_id:article_id || id,
          to_uid:from_uid,
          type:this.type,
          parent_id:parent_id || this.current_item.id,
          to_type:from_type,
          to_cid: this.current_item.id
        }
        await submit_comment(params)
        this.onRefresh()
        this.$emit('comment_count_change')
      }finally{
        // this.loading = false
        //自动失去焦点
        this.$refs.t_comment.blur()
        this.clear_data()
      }
    },
    async search_comments () {
      let {page,page_size,id} = this
      let params = {page:page-1,page_size,id}
      try {
        this.loading = true
        const {data} = await get_comments(params)
        this.total = data.total_elements || 0
        this.list = this.list.concat(data.data)
        if(this.current_item_id) {
          for(let i of this.list) {
            if(i.id === this.current_item_id) {
              i.show_more = true
              break
            }
          }
        }

        this.has_data = true
      }catch(e){
        console.log(e)
        this.error = true
      } finally {
        this.loading =  false
      }
    },
    //提交未读的
    async to_submit_read(arr,val) {
      await submit_read({id: this.id,ids:arr})
      this.list = this.list.map(i=>{
        if(i.id ===val.id) {
          i.reply_list = i.reply_list.map(item=>{
            if(arr.includes(item.id)) item.read = true
            return item
          })
        }
        return i
      })
      this.$emit('comment_count_change')
    },
  }
}
</script>
<style scoped>

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color:rgba(112, 112, 112, 0.5);
  }
}
.parent-item {
  margin-bottom: 7px;
  padding-bottom: 5px;
}
.one {
  display: flex;
  align-items: center;
  padding-bottom: 9px;
  & .one-text {
    width: 35px;
    height: 17px;
    line-height: 17px;
    text-align: center;
    background: #FFAB00;
    border-radius: 0px 0px 0px 0px;
    font-size: 10px;
    color: #FFFFFF;
  }
  & .one-operate{
    flex:1;
    text-align: right;
    font-size: 12px;
    color: #377DFF;
    line-height: 29px;
    cursor: pointer;
  }
  & .one-name {
    color: #4E5D78;
    line-height: 29px;
    padding: 0 10px 0;
  }
}
.two  {
  font-size: 14px;
  color: #000000;
  line-height: 15px;
  padding-bottom: 5px;
}
.three {
  color: #B0B7C3;
  line-height: 15px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  & >img {
    margin-left: 15px;
  }
  &.three1 {
    font-size: 12px;
    padding-bottom: 2px;
  }
}
.info{
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

}
.more {
  cursor: pointer;
  font-size: 12px;
  color: #707070;
  line-height: 15px;
  padding-bottom: 5px;
}
.reply {
  font-size: 12px;
  color: #000000;
  line-height: 20px;
  padding-bottom: 3px;
  & .reply-text {
    color: #FFAB00;
    padding-right: 11px;
  }
  & .from_name {
    color: rgba(55, 125, 255, 1);
  }
}
>>>.van-action-sheet__content {
  display: flex;
  flex-direction: column;
  & .common {
    flex:1;
    overflow: auto;
  }
}
.t-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & >img {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
}
>>>.van-field__body{
  height: 33px;
  background: #F3F3F3;
  border-radius: 17px;
  padding: 5px;
  & .van-field__control {
    &::placeholder {
      color: #707070;
      font-size: 12px;
    }

  }
}
.list {
  padding: 16px;
}
>>>.send .van-field__body{
 border-radius:17px 0 0 17px;
}
>>>.my-input.van-cell  {
  padding: 0;
}
.footer {
  padding: 10px 8px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  & .my-input {
    flex:1;
  }

}
.reply-text1 {
  padding-right: 4px;
}
>>>.van-list__finished-text {
  font-size: 12px;
}

</style>
<style>
.g_page_box {
  & .van-action-sheet {
    height: 70%;
    max-height: initial;
  }
}
</style>
