<template>
  <div class="common-tips" ref="common-title" :style="'padding-top:' + data.options.paddingTB + 'px'">
    <div class="common-tips-box">{{ data.options.title }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import { Button } from "vant";
Vue.use(Button);

export default {
  components: {},
  props: {
    data: null,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.common-tips {
  margin: 14px;
}
.common-tips-box {
  height: 40px;
  font-size: 14px;
  padding: 0px 8px;
  line-height: 40px;
  background: #eaf7ff;
}
</style>
