var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-box"},[_c('div',{ref:"common-article",staticClass:"common-article",style:('padding-top:' +
      _vm.data.options.paddingTB +
      'px ;padding-left:' +
      _vm.data.options.paddingLF +
      'px;padding-right:' +
      _vm.data.options.paddingLF +
      'px')},[(_vm.data.options.title != '')?_c('div',{staticClass:"common-article-title"},[_vm._v(" "+_vm._s(_vm.data.options.title)+" "),(_vm.data.options.type === 2)?_c('span',{staticClass:"more",on:{"click":_vm.toMore}},[_vm._v(" 查看更多 "),_c('van-icon',{attrs:{"name":"arrow"}})],1):_vm._e()]):_vm._e(),(_vm.data.options.type === 2)?_c('div',{staticClass:"common-video-list"},[(_vm.list.length === 0)?_c('div',{staticClass:"video-wrapper"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.list.length !== 0)?_c('div',{staticClass:"video-wrapper"},_vm._l((_vm.list),function(el){return _c('div',{key:el.id,staticClass:"common-video-list-item",on:{"click":function($event){return _vm.toPlay(el)}}},[_c('div',{staticClass:"cover_wrapper"},[(!el.cover)?_c('img',{staticClass:"cover",attrs:{"src":require("../../../../assets/img/article/video-def.png"),"alt":""}}):_c('div',{staticClass:"video-cover",style:({
                backgroundImage: `url(${_vm.articleMediaHost}/${el.cover})`,
              })}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../../../assets/img/article/play.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.toLocaleString(el.play_count)))])]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(_vm.format_duration(el.times)))])])]),_c('div',{staticClass:"video-title"},[_c('span',[_vm._v(" "+_vm._s(el.describe)+" ")])])])}),0):_vm._e()]):_c('div',{staticClass:"common-article-list"},[(_vm.data.options.list.length === 0)?[_vm._m(2),_vm._m(3),_vm._m(4)]:_vm._l((_vm.list),function(item,index){return _c('div',{key:'article-' + index,staticClass:"common-article-list-item",on:{"click":function($event){return _vm.onSel(index)}}},[_c('div',{staticClass:"common-article-list-item-info"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.desc || "无描述"))])]),(item.url && item.url !== '')?_c('img',{staticClass:"common-article-list-item-img",attrs:{"src":item.host + '/' + item.url}}):_vm._e()])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-video-list-item"},[_c('div',{staticClass:"cover_wrapper"},[_c('img',{staticClass:"cover",attrs:{"src":require("../../../../assets/img/article/video-def.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../../../assets/img/article/play.png"),"alt":""}}),_c('span',[_vm._v("212")])]),_c('div',{staticClass:"right"},[_vm._v("30:22")])])]),_c('div',{staticClass:"video-title"},[_vm._v("日常化生活中那些东西有毒害的科普视频")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-video-list-item"},[_c('div',{staticClass:"cover_wrapper"},[_c('img',{staticClass:"cover",attrs:{"src":require("../../../../assets/img/article/video-def.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../../../assets/img/article/play.png"),"alt":""}}),_c('span',[_vm._v("212")])]),_c('div',{staticClass:"right"},[_vm._v("30:22")])])]),_c('div',{staticClass:"video-title"},[_vm._v("日常化生活中那些东西有毒害的科普视频")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-article-list-item"},[_c('div',{staticClass:"common-article-list-item-info"},[_c('h3',[_vm._v("疣是什么？如何检测？")]),_c('p',[_vm._v("疣是皮肤上的一个肿块，是由于病毒侵入皮肤细胞，使…")])]),_c('img',{staticClass:"common-article-list-item-img",attrs:{"src":require("@/assets/img/img-article-nodata@3x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-article-list-item"},[_c('div',{staticClass:"common-article-list-item-info"},[_c('h3',[_vm._v("疣是什么？如何检测？")]),_c('p',[_vm._v("疣是皮肤上的一个肿块，是由于病毒侵入皮肤细胞，使…")])]),_c('img',{staticClass:"common-article-list-item-img",attrs:{"src":require("@/assets/img/img-article-nodata@3x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-article-list-item"},[_c('div',{staticClass:"common-article-list-item-info"},[_c('h3',[_vm._v("疣是什么？如何检测？")]),_c('p',[_vm._v("疣是皮肤上的一个肿块，是由于病毒侵入皮肤细胞，使…")])]),_c('img',{staticClass:"common-article-list-item-img",attrs:{"src":require("@/assets/img/img-article-nodata@3x.png")}})])
}]

export { render, staticRenderFns }