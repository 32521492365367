<template>
  <div
    class="common-map"
    ref="commonMap"
    :style="
      'margin:' + data.options.paddingTB + 'px ' + data.options.paddingLF + 'px'
    "
  >
    <div class="amap-page-container" style="height: 200px">
      <div id="map" style="height: 100%"></div>
      <!-- <el-amap
        vid="amapDemo"
        :center="[data.options.lng, data.options.lat]"
        :zoom="zoom"
        :plugin="plugin"
        class="amap-demo"
        :events="events"
      >
        <el-amap-marker
          vid="component-marker"
          :position="[data.options.lng, data.options.lat]"
        ></el-amap-marker>
      </el-amap>
      <embed src="//player.video.iqiyi.com/4e51af8158bbaf7c0023f0db23607e54/0/0/v_btxctx69k8.swf-albumId=1180487316647300-tvId=1180487316647300-isPurchase=0-cnId=undefined" allowFullScreen="true" quality="high" width="480" height="350" align="middle" allowScriptAccess="always" type="application/x-shockwave-flash"/>
     -->
    </div>
  </div>
</template>

<script>

export default {
  components: {  },
  props: {
    data: null
  },
  data() {
    return {
      // plugin: [
      //   {
      //     pName: "ToolBar",
      //     events: {
      //       init(instance) {
      //         console.log(instance);
      //       },
      //     },
      //   },
      // ],
      // zoom: 16,
      // center: [121.59996, 31.197646],
      // address: "",
      // events: {
        
      // },
      // lng: 0,
      // lat: 0,
      amap: null,
      infoWindow: null,
      marker: null,
    };
  },
  computed: {},
  mounted() {
    this.init_map()
  },
  methods: {
    init_map() {
      if(!this.data.options._location) {
        return
      }
      let location = this.data.options._location[0]
      this.amap = new AMap.Map("map", {
        zoom: 16,
        center: [location.lng, location.lat],
        resizeEnable: true
      })
      this.infoWindow = new AMap.InfoWindow({
        anchor: 'middle-right',
      })
      this.amap.clearMap()
      this.data.options._location.forEach(item=>{
        this.addMarker([item.lng, item.lat])
      })
    },
    //"//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
    addMarker(position) {
      let that = this
      let marker = new AMap.Marker({
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position:position,
        offset: new AMap.Pixel(-30, -30),
      });
      marker.setMap(that.amap);
    },
    updateIcon(index) {
      this.marker_arr[index].setIcon('//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png')
    } 
  },

  watch: {},
};
</script>

<style scoped>
.swipe-imgs {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.swipeBox__empty {
  font-size: 20px;
  color: #ccc;
  background: #fafafa;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

>>>.amap-icon img {
  width: 20px;
}
</style>
