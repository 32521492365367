<!--
 * @Author: magic
 * @Date: 2021-10-16 20:31:02
 * @LastEditors: g05047
 * @LastEditTime: 2021-10-20 16:27:03
 * @Description: file content
-->
<template>
  <div
    class="common-map"
    ref="commonMap"
    :style="
      'margin:' + data.options.paddingTB + 'px ' + data.options.paddingLF + 'px'
    "
    @click="toPlay"
  >
    <div class="video-box">
      <img
        class="video-cover video-cover-def"
        src="../../../../assets/img/article/video-def.png"
        alt=""
        v-if="!coverUrl"
      />
      <img class="video-cover" v-else :src="coverUrl" alt="" />
      <img
        class="video-play"
        src="../../../../assets/img/article/play_.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
// import { articles_actions } from "@/pages/article/service"

export default {
  props: {
    data: null,
  },
  computed: {
    videoUrl() {
      const url1 = this.data.options.path
        ? `${window.GLOBAL_CONFIG.articleMediaHost}/${this.data.options.path}`
        : ""
      const url2 = this.data.options.url

      return url1 ? url1 : url2
    },
    videoPlayType() {
      // 1.false video 播放 2.true 其他内嵌方式播放
      if (this.data.options.path || this.data.options.type === "oss_url") {
        return false
      }
      return true
    },
    coverUrl() {
      const url = this.data.options.cover
        ? `${window.GLOBAL_CONFIG.articleMediaHost}/${this.data.options.cover}`
        : ""

      return url
    },
  },
  methods: {
    toPlay() {
      // 埋点
      // let msg_id = this.$route.query.msg_id || 1
      // if (this.data.options.behavior && this.data.options.behavior !== "") {
      //   //埋点上报
      //   const action_id = this.data.options.behavior
      //   if (action_id) {
      //     articles_actions({
      //       msg_id: msg_id,
      //       action_id: action_id,
      //       article_id: this.$route.query.id,
      //       component_type: 1, // 文章
      //       target_id: this.data.options.video_no,
      //     })
      //   }
      // }
      if (this.data.options.video_no) {
        this.$router.push({
          path: "/video",
          query: {
            ...this.$route.query,
            video_no: this.data.options.video_no,
          },
        })
      } else {
        this.$notify("请重新配置视频")
      }
    },
  },
}
</script>

<style  >
.video-box iframe {
  width: 100%;
  height: 300px;
}
.common-video-player {
  width: 100%;
}

.video-box,
.video-cover {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  margin: 0 auto;
  border-radius: 4px;
}
.video-cover-def {
  width: 60%;
}
.video-play {
  position: absolute;
  width: 54px;
  height: 54px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
