<template>
  <div class="g_page_box">
    <!-- 患者提示 -->
    <PatientNotice v-if="msg_id && patientNotice !== false" :msg_id="msg_id" />

    <div class="g_main_content" id="a_box" style="background: #fff">
      <div class="g_agreement_box" id="mask_div_id" v-if="isShow"></div>
      <g-loading :loading="loading" />
      <van-empty v-if="!data" description="暂无文章信息" />
      <div class="g_fixed_bottom" v-if="isShow">
        <van-notice-bar left-icon="volume-o" :text="agreement_txt" />
      </div>
      <div
        class="article-content"
        v-for="(element, index) in list"
        :key="`widget-item-${index}`"
      >
        <common-title
          v-if="element.code === 'common-title'"
          :data="element"
        ></common-title>

        <common-copyright
          v-if="element.code === 'common-copyright'"
          :data="element"
        ></common-copyright>

        <common-article
          v-if="element.code === 'common-article'"
          :data="element"
        ></common-article>

        <common-header
          v-if="element.code === 'common-header'"
          :data="element"
          :date="data.send_time"
          @EVENT_CODE_TIPS="show_code_tips"
          @EVENT_NAMES_TIPS="show_name_tips"
        ></common-header>
        <common-rich-text
          v-if="element.code === 'common-rich-text'"
          :data="element"
        ></common-rich-text>
        <common-tips
          v-if="element.code === 'common-tips'"
          :data="element"
        ></common-tips>

        <common-img
          v-if="element.code === 'common-img'"
          :data="element"
        ></common-img>

        <common-map
          v-if="element.code === 'common-map'"
          :data="element"
        ></common-map>

        <common-video
          v-if="element.code === 'common-video'"
          :data="element"
        ></common-video>

        <hospital-check
          v-if="element.code === 'hospital-check'"
          @EVENT_CODE_TIPS="show_code_tips"
          @EVENT_NAMES_TIPS="show_name_tips"
          :data="element"
          @show_loading="show_loading"
          @hide_loading="hide_loading"
        ></hospital-check>

        <hospital-form
          v-if="element.code === 'hospital-form'"
          :data="element"
        ></hospital-form>

        <hospital-doctor
          v-if="element.code === 'hospital-doctor'"
          :data="element"
        ></hospital-doctor>

        <hospital-approved
          v-if="element.code === 'hospital-approved'"
          :data="element"
        ></hospital-approved>

        <hospital-doctor-again
          v-if="element.code === 'hospital-doctor-again'"
          :data="element"
        ></hospital-doctor-again>

        <hospital-approved-again
          v-if="element.code === 'hospital-approved-again'"
          :data="element"
        ></hospital-approved-again>

        <hospital-health-log
          v-if="element.code === 'health-log'"
          :data="element"
        ></hospital-health-log>

        <hospital-call
          v-if="element.code === 'hospital-call'"
          :data="element"
        ></hospital-call>

        <form-radio
          v-if="element.code === 'form-radio'"
          :data="element"
        ></form-radio>

        <shop-list
          v-if="element.code === 'shop-list'"
          :data="element"
        ></shop-list>

        <van-popup v-model="show" position="bottom" :style="{ height: '80%' }">
          <div class="ai-tips">
            <div class="ai-tips-header">
              <div class="ai-tips-header-title">
                {{ title }}
              </div>
              <div
                class="ai-tips-header-action"
                @click="
                  show = false
                  title = ''
                "
              ></div>
            </div>
            <div class="ai-tips-bodyer">
              <template v-if="qaList && qaList.length > 0">
                <div
                  class="ai-tips-bodyer-item"
                  v-for="(item, index) in qaList"
                  :key="'qalist_' + index"
                >
                  <h3>{{ item.question }}</h3>
                  <p>
                    {{ item.content }}
                  </p>
                </div>
              </template>

              <van-empty
                v-if="qaList && qaList.length == 0"
                description="暂无简介"
              />
            </div>
          </div>
        </van-popup>
      </div>
    </div>
    <!-- 显示评论 -->
    <div
      class="t-img"
      v-if="source !== 'yunying' && [1, 2].includes(GLOBAL_CONFIG_source)"
    >
      <div class="t t-1">
        <img @click="show_comments" src="../../assets/img/comment-chart.png" />
        {{ comments_data.comment_count }}
      </div>
      <van-badge
        :content="comments_data.no_read_count || 0"
        class="t t-2"
        :max="99"
      >
        <img @click="jup_no_read" src="../../assets/img/no-read.png" />
      </van-badge>
    </div>
    <Comments
      :visible.sync="visible"
      :id="id"
      v-if="visible"
      :comment_count="comments_data.comment_count || 0"
      @comment_count_change="comment_count_change"
    />
    <!-- 健康小助手，长海专用 -->
    <VueDraggableResizable
      v-if="msg_id && [6].includes(GLOBAL_CONFIG_source)"
      class="ai_diagnosis_box"
      w="auto"
      h="auto"
      :resizable="false"
      @click.native="to_ai_diagnosis"
    >
      <van-badge
        v-if="unread_num"
        class="badge"
        :content="unread_num"
        max="99"
      />
      <img src="../../assets/img/article/ai.png" class="img" />
    </VueDraggableResizable>
  </div>
</template>

<script>
// import { Dialog } from 'vant';

import {
  get_text,
  articles_read,
  get_comments_detail,
  msg_to_pack_id,
  get_unread,
} from "./service"
import { mapState, mapActions } from "vuex"
import { getSession } from "../../utils/session"
import { get_url_params } from "../../utils/format"
/** 通用 */
import CommonTitle from "@/pages/article/components/common/common-title"
import CommonHeader from "@/pages/article/components/common/common-header"
import CommonRichText from "@/pages/article/components/common/common-rich-text"
import CommonTips from "@/pages/article/components/common/common-tips"
import CommonImg from "@/pages/article/components/common/common-img"
import CommonMap from "@/pages/article/components/common/common-map"
import CommonVideo from "@/pages/article/components/common/common-video"
import CommonArticle from "@/pages/article/components/common/common-article"
import CommonCopyright from "@/pages/article/components/common/common-copyright"

// /** 医院 */
import HospitalCheck from "@/pages/article/components/hospital/hospital-check"
import HospitalForm from "@/pages/article/components/hospital/hospital-form"
import HospitalDoctor from "@/pages/article/components/hospital/hospital-doctor"
import HospitalApproved from "@/pages/article/components/hospital/hospital-approved"
import ShopList from "@/pages/article/components/shop/index.vue"
import HospitalDoctorAgain from "@/pages/article/components/hospital/hospital-doctor-again"
import HospitalApprovedAgain from "@/pages/article/components/hospital/hospital-approved-again"
import HospitalHealthLog from "@/pages/article/components/hospital/hospital-health-log.vue"
import HospitalCall from "@/pages/article/components/hospital/hospital-call.vue"

// /** 表单 */
import FormRadio from "@/pages/article/components/form/form-radio"
import { get_wordslots_tips, post_readed_tags } from "@/pages/article/service"

//评论
import Comments from "./comments"

import watermark from "watermark-dom"

import PatientNotice from "./PatientNotice.vue"

import VueDraggableResizable from "../../components/vue-draggable-resizable/components/vue-draggable-resizable.vue"

export default {
  data() {
    return {
      agreement_txt: GLOBAL_CONFIG.agreement_txt,
      data: null,
      isShow: GLOBAL_CONFIG.isShowAgreement,
      list: [], //控件
      loading: false,
      id: 0,
      title: "",
      event_type: "",
      qaList: [],
      show: false,
      mode: "",
      labs: undefined,
      examines: {
        introduction: "",
        part: "",
        standard_id: "",
        standard_name: "",
        un_suitable: "",
      },
      start_time: new Date().getTime(),
      source: this.$route.query.source,
      GLOBAL_CONFIG_source: GLOBAL_CONFIG.source,
      article_url: "",
      time_send: false,
      visible: false,
      comments_data: {},
      unread_num: 0, // 未读消息数
      msg_id: "",
      patientNotice: GLOBAL_CONFIG.patientNotice,
    }
  },
  filters: {
    FilterStatus(val) {
      if (val === "") {
        return "暂无简介"
      }
    },
  },
  components: {
    CommonTitle,
    CommonHeader,
    CommonRichText,
    CommonTips,
    CommonImg,
    CommonMap,
    HospitalCheck,
    HospitalForm,
    HospitalDoctor,
    CommonVideo,
    FormRadio,
    CommonArticle,
    CommonCopyright,
    HospitalApproved,
    ShopList,
    Comments,
    HospitalDoctorAgain,
    HospitalApprovedAgain,
    HospitalHealthLog,
    PatientNotice,
    VueDraggableResizable,
    HospitalCall,
  },
  computed: {
    ...mapState("commonData", {
      geolocation: (state) => state.geolocation,
    }),
    ...mapState("no_read", {
      no_read: (state) => state.no_read,
    }),
    // msg_id() {
    //   return this.$route.query.msg_id
    // },
  },
  watch: {
    $route() {
      this.send_stay(new Date().getTime() - this.start_time)
      this.loadData()
      this.article_url = this.$route.fullPath
      this.send_read()
      this.get_unread()
    },
  },
  mounted() {
    this.loadData()
    this.article_url = this.$route.fullPath
    this.send_read()
    this.get_unread()
    if (this.source !== "yunying") {
      window.addEventListener("pagehide", this.leave, false)
      document.addEventListener("visibilitychange", this.leave)
      window.onbeforeunload = this.leave
    }

    if (this.isShow) {
      this.$nextTick(() => {
        watermark.init({
          watermark_parent_node: "mask_div_id",
          watermark_txt: GLOBAL_CONFIG.water_marker,
          watermark_x: 20, //水印起始位置x轴坐标
          watermark_y: 20, //水印起始位置Y轴坐标
          watermark_rows: 20, //水印行数
          watermark_cols: 20, //水印列数
          watermark_x_space: 100, //水印x轴间隔
          watermark_y_space: 50, //水印y轴间隔
          watermark_color: "#aaa", //水印字体颜色
          watermark_alpha: 0.2, //水印透明度
          watermark_fontsize: "15px", //水印字体大小
          watermark_font: "微软雅黑", //水印字体
          watermark_width: 210, //水印宽度
          watermark_height: 80, //水印长度
          watermark_angle: 20, //水印倾斜度数
        })
      })
    }
  },
  //评论自动弹出
  beforeRouteEnter(to, from, next) {
    // 先判断里面是不是有 \u0026
    if (to.fullPath.includes("%5Cu0026")) {
      const queryStr = to.fullPath
        .replaceAll("%5Cu0026", "%26")
        .replaceAll("\u0026", "%26")
      next({
        path: to.path,
        query: get_url_params(decodeURIComponent(queryStr)),
      })
      return
    }

    const article_ids = Object.keys(GLOBAL_CONFIG.digital_human_article_map)
    if (
      article_ids.includes(to.query.id) &&
      !["/digital-human"].includes(from.path)
    ) {
      next({
        path: "/digital-human",
        query: {
          redirect: encodeURIComponent(to.fullPath),
          video_name: GLOBAL_CONFIG.digital_human_article_map[to.query.id],
        },
      })
      return
    }
    next((vm) => {
      if (from.path === "/article-no-read-comments") {
        if (vm.$route.query.source !== "yunying" && vm.no_read) {
          vm.visible = true
        }
      }
    })
  },
  beforeDestroy() {
    this.send_stay(new Date().getTime() - this.start_time)
    if (this.source !== "yunying") {
      window.removeEventListener("pagehide", this.leave, false)
      document.removeEventListener("visibilitychange", this.leave)
      window.onbeforeunload = null
    }
  },
  methods: {
    ...mapActions("commonData", ["get_geolocation"]),
    async send_read() {
      await this.get_geolocation()
      await articles_read({
        article_id: this.id,
        msg_id: this.msg_id,
        lon: this.geolocation.lng,
        lat: this.geolocation.lat,
      })
    },
    leave() {
      this.send_stay(new Date().getTime() - this.start_time)
    },
    send_stay(mills) {
      if (this.source === "yunying") return
      if (this.time_send) return
      this.time_send = true

      window.navigator.sendBeacon(
        `${GLOBAL_CONFIG.backend_server}/api/v1/mp/articles/stays`,
        JSON.stringify({
          msg_id: this.msg_id,
          url: this.article_url,
          stay_mills: mills,
          user_id: getSession().user_id,
        })
      )
      setTimeout(() => {
        this.time_send = false
      }, 200)
    },
    // 兼容url 有unicode的问题 主要是wx 物理返回
    formatQuery() {
      const search = location.hash
        .replaceAll("%5Cu0026", "%26")
        .replaceAll("\u0026", "%26")
      const query = get_url_params(decodeURIComponent(search))
      return query
    },
    loadData() {
      const query = this.formatQuery()
      this.id = query.id
      this.msg_id = query.msg_id
      this.source = query.source

      this.event_type = query.event_type || ""
      let obj = {
        plan_id: query.plan_id,
        plan_type: query.plan_type,
        msg_id: query.msg_id,
        encry: GLOBAL_CONFIG.article_encry,
      }
      this.get_data(this.id, obj)
      //获取评论数量
      if (
        query.source !== "yunying" &&
        [1, 2].includes(this.GLOBAL_CONFIG_source)
      ) {
        this.search_comments_detail()
      }
    },
    //显示评论
    show_comments() {
      this.visible = true
    },
    //评论历史
    jup_no_read() {
      this.$router.push("/article-no-read-comments")
    },
    //提交评论刷新数量
    comment_count_change() {
      this.search_comments_detail()
    },
    //当前文章的统计数量
    async search_comments_detail() {
      const { id } = this.formatQuery
      try {
        this.loading = true
        const { data } = await get_comments_detail(id)
        this.comments_data = data
      } finally {
        this.loading = false
      }
    },
    async show_code_tips(obj) {
      this.show = true
      this.title = obj.name
      this.qaList = []
      const { data } = await get_wordslots_tips({ ids: [obj.id] })
      if (data[obj.id]) {
        this.qaList = data[obj.id]
      }
    },
    async show_name_tips(obj) {
      this.show = true
      this.title = obj.name
      this.qaList = []
      const { data } = await get_wordslots_tips({ names: [obj.name] })
      for (var key in data) {
        this.qaList = data[key]
      }
    },
    async get_data(id, obj) {
      this.loading = true
      try {
        const { data } = await get_text(id, obj)
        this.data = data
        this.loading = false
        this.start_time = new Date().getTime()
        this.time_send = false
        this.list = JSON.parse(this.data.content)
        for (let v of this.list) {
          if (v.actions && v.actions.length) {
            //新tags
            this.post_read_tags(v)
            break
          }
        }
      } finally {
        this.loading = false
      }
    },
    async post_read_tags(obj) {
      const param = {
        msg_id: this.msg_id,
        add: obj.add_tags,
        remove: obj.delete_tags,
      }
      await post_readed_tags(param)
    },
    show_loading() {
      this.loading = true
    },
    hide_loading() {
      this.loading = false
    },
    async get_unread() {
      if (!this.msg_id || this.GLOBAL_CONFIG_source !== 6) return
      const { data } = await get_unread({ msg_id: this.msg_id })
      this.unread_num = +data.unread_count
    },
    async to_ai_diagnosis() {
      const { data } = await msg_to_pack_id({ msg_id: this.msg_id })
      location.href = `${GLOBAL_CONFIG.ai_diagnosis_url}/login?pack_id=${data.pack_id}&source=wz`
    },
  },
}
</script>

<style scoped>
.article-content {
  padding: 0 10px;
}
.ai-tips {
}
.ai-tips-header {
  height: 80px;
  background: #0013a8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ai-tips-header-title {
  font-size: 24px;
  height: 100%;
  color: #fff;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.ai-tips-header-action {
  font-size: 24px;
  height: 100%;
  color: #fff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  opacity: 0.4;
}

.ai-tips-bodyer {
  padding: 20px 14px;
}

.ai-tips-bodyer-item {
  padding: 20px 0px;
}

.ai-tips-bodyer-item h3 {
  padding: 0px 0px;
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}

.ai-tips-bodyer-item p {
  margin: 10px 0px;
  font-size: 16px;
  color: #565656;
  line-height: 1.6;
}

.g_main_content {
  background: #f7faff;
  padding-bottom: 80px;
}

.box {
  padding: 18px 18px 25px 18px;
  background: #fff;
}
.t-img {
  position: fixed;
  top: 50%;
  transform: translateY(50%);
  z-index: 2000;
  right: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.25);
  line-height: 11px;
  & .t-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    & > img {
      width: 30px;
      height: 30px;
    }
  }
  & .t-2 {
    & > img {
      width: 30px;
      height: 30px;
    }
  }
}
.g_agreement_box {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  font-weight: 400;
}
.g_fixed_bottom {
  left: 0px;
  right: 0;
  top: 0px;
  position: fixed;
  pointer-events: none;
  z-index: 888;
}
.ai_diagnosis_box {
  border-radius: 50%;
  position: fixed;
  bottom: 25px;
  right: 20px;
  & .img {
    width: 94px;
    height: 91px;
  }
  & .badge {
    background-color: #f41c23;
    padding: 2px 6px;
    position: absolute;
    left: 60px;
    top: 2px;
    white-space: nowrap;
  }
}
</style>
