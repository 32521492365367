
<template>
  <div class="hospital-form" ref="hospital-form" :style="'padding-top:' + data.options.paddingTB + 'px'">
    <div class="hospital-form-box" @click="onTap()">
      <div class="header">
        <img src="@/assets/widget/img-ai-logo@2x.png" />
        <div class="info">
          <h3>{{ data.options.title }}</h3>
          <p>{{ data.options.des }}</p>
          <div class="btn">{{ data.options.text }}</div>
        </div>
      </div>

      <!-- <div class="action">
        <div class="btn">{{ data.options.text }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Button } from "vant";
import {articles_actions} from "@/pages/article/service";
Vue.use(Button);

export default {
  components: {},
  props: {
    data: null,
  },

  data() {
    return {
      source: this.$route.query.source
    };
  },
  computed: {},
  methods: {
    async onTap() {
      this.$emit('show_loading')
      try {
        let msg_id = this.$route.query.msg_id || 1;
        const action_id = this.data.options.behavior
        //埋点上报
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 2, // 量表
          target_id: this.data.options.fid,
        });
      } finally {
        this.$emit('hide_loading')
        this.$router.push({
          path: "/gauge-task",
          query: {
            id: this.data.options.fid,
            msg_id: this.$route.query.msg_id,
            source: this.source === 'yunying'?'h5':'app',
          },
        });
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.hospital-form {
  background: #fff;
}
.hospital-form-box {
  margin: 14px;
  background: #f8fdff;
  padding: 10px 0px;
}
.hospital-form-box .header {
  margin: 4px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.hospital-form-box .header img {
  width: 60px;
  height: 60px;
}
.hospital-form-box .header .info {
  margin-left: 8px;
}
.hospital-form-box .header .info h3 {
  font-size: 16px;
  color: #222222;
  margin: 0px;
  font-weight: 600;
}
.hospital-form-box .header .info p {
  margin: 0px;
  font-size: 14px;
  color: #565656;
  line-height: 1.4;
}
.hospital-form-box .action {
  width: 100%;
}
.hospital-form-box .header .info .btn {
  margin-top: 10px;
  height: 28px;
  color: #fff;

  background: #1890ff;
  padding: 0 10px;
  line-height: 28px;
  text-align: center;
  border-radius: 14px;
  float: left;
}
</style>
