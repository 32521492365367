<!--
 * @Author: magic
 * @Date: 2022-03-21 10:22:27
 * @LastEditors: g05047
 * @LastEditTime: 2022-03-25 09:26:24
 * @Description: file content
-->
<template>
  <div
    class="common-title"
    ref="common-title"
    :style="'padding-top:' + data.options.paddingTB + 'px'"
  >
    <h1>{{ data.options.title }}</h1>
  </div>
</template>

<script>
import Vue from "vue";
import { Button } from "vant";
Vue.use(Button);

export default {
  components: {},
  props: {
    data: null,
    editMode: () => {
      return false;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  watch: {
    data: {
      deep: true,
      handler: function handler() {
        //this.getSize();
      },
    },
  },
};
</script>

<style scoped>
.common-title {
  padding: 8px 14px;
  & .common-title-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e5f2ff;
    position: absolute;
    margin-top: 4px;
    z-index: 1;
  }
  & .common-title-circle-small {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #edfdfc;
    position: absolute;
    margin-left: 30px;
    margin-top: 18px;
    z-index: 0;
  }
  & h1 {
    position: relative;
    font-size: 20px;
    line-height: 1.2;
    z-index: 3;
    font-weight: 600;
    color: #1d2530;
  }
}
</style>
