<template>
  <div
    class="hospital-doctor"
    ref="hospital-approved"
    :style="'padding-top:' + data.options.paddingTB + 'px'"
  >
    <template v-if="data.options.mode === '1'">
      <div class="h-d-i-h" v-if="data.options.data.hospital_name">
        {{ data.options.data.hospital_name }}
      </div>
      <div
        class="hospital-room-box"
        @click="onRouterRoom(data.options.data)"
        :style="{
          'border-radius': !data.options.data.hospital_name
            ? '8px'
            : '0px 8px 8px 8px',
        }"
      >
        <img class="img1" src="../../../../assets/img/ks.png" />
        <div class="hospital-room-box-info">
          <h1>{{ data.options.data.name }}</h1>
          <p>免排队 在线预约专家门诊</p>
        </div>
        <!-- <img class="img2" src="../../../../assets/img/img-room-right.png" /> -->
        <div class="room-box-btn">去挂号</div>
        <!-- <div :class="['type', 'type1']">科室</div> -->
      </div>
    </template>
    <template v-else>
      <h3 v-if="data.options.title">
        {{ data.options.title }}
      </h3>
      <div
        class="doctor-box"
        v-if="data.options.list && data.options.list.length"
      >
        <div class="doctor-box-content">
          <div
            v-for="(item, index) in data.options.list"
            :key="'doctor-' + index"
            @click="to_url(item)"
            class="hospital-doctor-item_"
          >
            <div class="h-d-i-h" v-if="item.hospital_name">
              {{ item.hospital_name }}
            </div>
            <div
              class="hospital-doctor-item"
              :style="{
                'border-radius': !item.hospital_name
                  ? '8px'
                  : '0px 8px 8px 8px',
              }"
            >
              <div class="hospital-doctor-item-header">
                <img :src="doctor_head_img(item.image_url)" />
                <div style="flex: 1">
                  <div class="hospital-doctor-item-info">
                    <h3 style="color: #333">{{ item.name }}</h3>
                    <p v-if="item.title">{{ item.title }}</p>
                  </div>
                  <p class="department_" v-if="item.department_name">
                    {{ item.department_name }}
                  </p>
                </div>
                <div class="hospital-doctor-item-btn">去挂号</div>
              </div>
              <div class="dct-desc" v-if="item.desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import Vue from "vue"
// import { Button } from "vant"
import { articles_actions } from "@/pages/article/service"
import { doctor_head_img } from "../../../../utils/imgUrl"
// Vue.use(Button)

export default {
  props: {
    data: null,
    editMode: () => {
      return false
    },
  },
  filters: {
    //医生职称:1-主任医师；2-副主任医师；3-主治医师；4-医师;5-住院医师
  },
  methods: {
    doctor_head_img,
    onRouterRoom(item) {
      // 预览不可点
      if (this.$route.query.source === "yunying") return
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 5, // 科室挂号
          department_code: this.data.options.data.code,
          department_name: this.data.options.data.name,
        })
      }
      // 温附一
      if (GLOBAL_CONFIG.source === 3) {
        location.href = `${
          GLOBAL_CONFIG.wenfuyi_config?.basisHost
        }/Show/yuyue/departmentpb?id=${
          this.data.options.data.id || this.data.options.data.code
        }&name=${encodeURIComponent(this.data.options.data.name)}&type=${
          this.data.options.data.category || 1
        }&trans_id=${msg_id}&code=${this.data.options.data.code}&fcode=${
          this.data.options.data.parent_code
        }`
      }
      // 长海
      else if ([6, 8].includes(GLOBAL_CONFIG.source)) {
        let redirect = encodeURIComponent(this.data.options.data.url)
        this.$router.push({
          path: "/guide-changhai",
          query: { redirect, msg_id },
        })
      }
      // 温州市中心
      else if (GLOBAL_CONFIG.source === 7) {
        const data = this.data.options.data
        const redirect_uri = encodeURIComponent(
          `${
            GLOBAL_CONFIG.wzszx_config?.basisHost
          }/patient/registration/#/appointment-choise-doctor?branchCode=${
            data.hospital_id
          }&branchName=${encodeURIComponent(data.hospital_name)}&deptId=${
            data.code
          }&deptName=${encodeURIComponent(data.name)}`
        )
        location.href = `${GLOBAL_CONFIG.wzszx_config?.basisHost}/gateway/patient/connect/wechat?redirect_uri=${redirect_uri}&hospitalCode=2D8E7304D06441708AC60731D3BCCDEE`
        return
      }
      else if (item.s_url || item.url) {
        location.href = item.s_url || item.url
      } else {
        this.$router.push({
          path: "/select-doctor",
          query: {
            hospital_id: this.data.options.data.hospital_id,
            department_code: this.data.options.data.code,
            department_name: this.data.options.data.name,
          },
        })
      }
    },
    to_url(item) {
      // 预览不可点
      if (this.$route.query.source === "yunying") return
      let msg_id = this.$route.query.msg_id || 1
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 5, // 科室挂号
          doctor_code: item.code,
          doctor_name: item.name,
          department_code: item.department_code,
        })
      }
      // 温附一
      if (GLOBAL_CONFIG.source === 3) {
        location.href = `${GLOBAL_CONFIG.wenfuyi_config?.basisHost}/Show/yuyue/doctorpb2/?yhid=${item?.code}&brbh=${this.$route.query.patient_id}&lx=0&trans_id=${msg_id}`
      }
      //厦大中山
      else if (GLOBAL_CONFIG.source === 5) {
        location.href = `${GLOBAL_CONFIG.xiadazhongshang_config?.basisHost}/routeinteface/enterDoctorTime.htm?doctorId=${item.code}`
      }
      //长海
      else if ([6, 8].includes(GLOBAL_CONFIG.source)) {
        let redirect = encodeURIComponent(item.url)
        this.$router.push({
          path: "/guide-changhai",
          query: { redirect, msg_id },
        })
      }
      // 温州市中心
      else if (GLOBAL_CONFIG.source === 7) {
        let redirect_uri = encodeURIComponent(
          `${
            GLOBAL_CONFIG.wzszx_config?.basisHost
          }/patient/registration/#/doctor-info?doctorId=${
            item.code
          }&doctorName=${encodeURIComponent(
            item.name
          )}&deptId=&deptName=&branchCode=100158001&branchName=温州市中心医院`
        )
        location.href = `${GLOBAL_CONFIG.wzszx_config?.basisHost}/gateway/patient/connect/wechat?redirect_uri=${redirect_uri}&hospitalCode=2D8E7304D06441708AC60731D3BCCDEE`
      } else if (item.s_url || item.url) {
        location.href = item.s_url || item.url
      } else {
        this.$router.push({
          path: "/doctor-page",
          query: {
            hospital_id: item.hospital_id,
            department_code: item.department_code,
            department_name: item.department_name,
            doctor_id: item.code,
          },
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.dct-desc {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 13px;
  color: #939aa6;
  line-height: 18px;
  margin-left: 52px;
  margin-top: 4px;
}
.room-box-btn {
  width: 68px;
  height: 28px;
  color: #fff;
  font-weight: bold;
  background: #5bc98e;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  /* font-size: 14px; */
  font-weight: 600;
  font-size: 12px;
}
.h-d-i-h {
  height: 21px;
  // line-height: 21px;
  background: linear-gradient(90deg, #fdf3e6 0%, #fcf0dd 45%, #fcecd5 100%);
  box-shadow: 0px 0px 12px 0px rgba(176, 183, 194, 0.12);
  border-radius: 4px 16px 0px 0px;
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  font-size: 12px;
  color: #b9875f;
  margin-left: 14px;
}

.hospital-doctor {
  padding: 14px 0;

  overflow-x: hidden;
  & h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 14px;
    margin-bottom: 20px;
    color: #1d2530;
  }
}
.hospital-doctor-item {
  // border: 1px #eee solid;
  // width: 72vw;
  // height: calc(270px * 0.6);
  // height: 70px;
  padding: 16px 12px;
  // border-radius: 8px;
  // margin-right: 3vw;
  // float: left;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(161, 165, 172, 0.2);
  border-radius: 0px 8px 8px 8px;
  margin: 0 14px;
  .img2 {
    width: 14px;
    height: 14px;
  }
}

.hospital-room-box {
  background: #fff;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 14px;
  box-shadow: 0px 0px 12px 0px rgba(161, 165, 172, 0.2);
  border-radius: 0px 8px 8px 8px;
  & .hospital-room-box-info {
    flex: 1;
    padding: 0 8px;
    padding-left: 12px;
    & > h1 {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      line-height: 20px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    & > p {
      padding-top: 5px;
      color: #666;
      font-size: 12px;
    }
  }
  & .img1 {
    width: 40px;
    height: 40px;
  }
  & .img2 {
    width: 14px;
    height: 14px;
  }
}

.doctor-box {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.doctor-box::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.doctor-box-content {
  // width: 1200px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.hospital-doctor-item_ + .hospital-doctor-item_ {
  margin-top: 12px;
}

.hospital-doctor-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.hospital-doctor-item-header img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.hospital-doctor-item-bodyer {
  margin-top: 10px;
  color: #818181;
}

.hospital-doctor-item-bodyer p.department {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 2;
  color: #565656;
}
.department_ {
  font-size: 14px;
  color: #666666;
  margin-top: 6px;
  margin-left: 16px;
}

.hospital-doctor-item-bodyer p.intro {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.4;
}

.hospital-doctor-item-btn {
  width: 68px;
  height: 28px;
  color: #fff;
  font-weight: bold;
  background: #2680eb;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  /* font-size: 14px; */
  font-weight: 600;
  font-size: 12px;
}

.hospital-doctor-item-info {
  flex: 1;
  padding-left: 12px;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  & h3 {
    // line-height: 20px;
    margin: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
  }
  & p {
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    border-left: 1px solid #939aa6;
    padding-left: 4px;
    margin-left: 8px;
    & span {
      background: #0088ff;
      padding: 2px 4px;
      color: #fff;
      border-radius: 4px;
    }
  }
  & .intro {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.4;
  }
}
.type {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 20px;
  // background: #e8fbf2;
  border-radius: 0px 8px 0px 8px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.type1 {
  color: #6bd5a4;
  background: #e8fbf2;
}
.type2 {
  color: #468eff;
  background: #e7f2fe;
}
</style>

