<!--
 * @Author: magic
 * @Date: 2021-10-16 20:31:02
 * @LastEditors: g05047
 * @LastEditTime: 2021-10-20 16:27:03
 * @Description: file content
-->
<template>
  <div
    class="common-map"
    ref="commonMap"
    :style="
      'margin:' + data.options.paddingTB + 'px ' + data.options.paddingLF + 'px'
    "
  >
    <div class="video-box" v-if="videoPlayType">
      {{ data.options.url }}
    </div>
    <!-- <video
      data-autoplay
      loop="loop"
      controls="controls"
      v-if="data.options.url === ''"
      src="https://eshowtest.oss-cn-shanghai.aliyuncs.com/ui/15s.mp4"
      class="common-video-player"
      data-object-fit
    >
      抱歉，您的浏览器不支持内嵌视频
    </video> -->
    <video
      data-autoplay
      loop="loop"
      controls="controls"
      v-else
      :src="videoUrl"
      class="common-video-player"
      data-object-fit
    >
      抱歉，您的浏览器不支持内嵌视频
    </video>
  </div>
</template>
<script>
export default {
  props: {
    data: null,
  },
  computed: {
    videoUrl() {
      const url1 = this.data.options.path
        ? `${window.GLOBAL_CONFIG.articleMediaHost}/${this.data.options.path}`
        : ""
      const url2 = this.data.options.url
      return url1 ? url1 : url2
    },
    videoPlayType() {
      // 1.false video 播放 2.true 其他内嵌方式播放
      if (this.data.options.path || this.data.options.type !== "video_code") {
        return false
      }
      return true
    },
  },
}
</script>
<style  >
.video-box iframe {
  width: 100%;
  height: 300px;
}
.common-video-player {
  width: 100%;
}
</style>