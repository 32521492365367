<template>
  <div
    class="card-container-shop"
    :style="'padding:' + data.options.paddingTB + 'px 18px'"
  >
    <div class="shop-list">
      <template>
        <div
          class="shop-list-item"
          v-for="(item, index) in data.options.list"
          :key="'p' + index"
          @click="onSel(index)"
        >
          <img class="shop-list-item-img" :src="item.images[0].url" />
          <div class="shop-list-item-info">
            <div class="name">
              {{ item.name }}
            </div>
            <div class="buy">
              <div class="price-txt">¥{{ item.price / 100 }}元</div>
              <div class="btn-buy">去购买</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
import { getSession } from "@/utils/session";
import { articles_actions } from "@/pages/article/service";
// import { Image } from "vant";

// Vue.use(Image);
Vue.use(Lazyload);

export default {
  components: {
    // "van-image": Image
  },
  props: {
    data: null,
    editMode: () => {
      return false;
    },
  },
  data() {
    return {
      offsetWidth: 0,
      width: 0,
      height: 0,
      maxWidth: 0,
      isEditMode: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.data.options.list === 0) {
        this.isEditMode = true;
      }
    });
  },
  methods: {
    onSel(index) {
      let msg_id = this.$route.query.msg_id || 1;
      let data = this.data.options.list[index];
      if (this.data.options.behavior && this.data.options.behavior !== "") {
        //埋点上报
        const action_id = this.data.options.behavior;
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 3, // 商品
          target_id: data.id,
        });
      }
      let s_id = this.$route.query.msg_id ? this.$route.query.msg_id : "-999";
      let link =
        data.link +
        "&a_id=" +
        s_id +
        "&s_id=" +
        s_id +
        "&user_key=" +
        getSession().user_id;

      //alert(link);
      window.location.href = link;
    },
  },
  watch: {
    data: {
      deep: true,
      handler: function handler() {
        //this.getSize();
      },
    },
  },
};
</script>

<style scoped  >
.card-container-shop {
  width: 100%;
  padding: 20px 0px;
}

.shop-list-item {
  height: 114px;
  padding: 40px 10px;
  display: flex;
  background: #fff;
  align-items: center;
  align-content: flex-start;
  box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}

.shop-list-item-img {
  width: 90px;
  height: 90px;
}
.shop-list-item-info {
  height: 90px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin-left: 10px;
}
.shop-list-item-info .name {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
}
.shop-list-item-info .buy{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shop-list-item-info .buy .price-txt{
  font-size: 16px;
  font-weight: 500;
  color: #B41400;
  flex: 1;
}

.shop-list-item-info .buy .btn-buy{
  width: 60px;
  height: 24px;
  color: #fff;
  font-weight: bold;
  background: #ea7553;
  background-image: linear-gradient(to right, #ea7553 , #ff3c00);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}
</style>
