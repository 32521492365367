var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"hospital-approved",staticClass:"hospital-doctor",style:('padding-top:' + _vm.data.options.paddingTB + 'px')},[(_vm.data.options.mode === '1')?[(_vm.data.options.data.hospital_name)?_c('div',{staticClass:"h-d-i-h"},[_vm._v(" "+_vm._s(_vm.data.options.data.hospital_name)+" ")]):_vm._e(),_c('div',{staticClass:"hospital-room-box",style:({
        'border-radius': !_vm.data.options.data.hospital_name
          ? '8px'
          : '0px 8px 8px 8px',
      }),on:{"click":_vm.onRouterRoom}},[_c('img',{staticClass:"img1",attrs:{"src":require("../../../../assets/img/ks.png")}}),_c('div',{staticClass:"hospital-room-box-info"},[_c('h1',[_vm._v(_vm._s(_vm.data.options.data.name))]),_c('p',[_vm._v("免排队 在线问诊专家门诊")])]),_c('div',{staticClass:"room-box-btn"},[_vm._v("去问诊")])])]:[(_vm.data.options.title)?_c('h3',[_vm._v(" "+_vm._s(_vm.data.options.title)+" ")]):_vm._e(),(_vm.data.options.list && _vm.data.options.list.length > 0)?_c('div',{staticClass:"doctor-box"},[_c('div',{staticClass:"doctor-box-content"},_vm._l((_vm.data.options.list),function(item,index){return _c('div',{key:'doctor-' + index,staticClass:"doctor-box-content_",on:{"click":function($event){return _vm.to_url(item)}}},[(item.info.hospital)?_c('div',{staticClass:"h-d-i-h"},[_vm._v(" "+_vm._s(item.info.hospital)+" ")]):_vm._e(),_c('div',{staticClass:"hospital-doctor-item",style:({
              'border-radius': !item.info.hospital
                ? '8px'
                : '0px 8px 8px 8px',
            })},[_c('div',{staticClass:"hospital-doctor-item-header"},[_c('img',{attrs:{"src":_vm.doctor_head_img(item.info.headImage)}}),_c('div',{staticClass:"center"},[_c('div',{staticClass:"hospital-doctor-item-info"},[_c('h3',[_vm._v(_vm._s(item.info.name))]),(item.info.position)?_c('p',[_vm._v(_vm._s(item.info.position))]):_vm._e()]),(item.info.department)?_c('p',{staticClass:"department_"},[_vm._v(" "+_vm._s(item.info.department)+" ")]):_vm._e()]),_c('div',{staticClass:"hospital-doctor-item-btn"},[_vm._v("去问诊")])]),(item.info.speciality)?_c('div',{staticClass:"hospital-doctor-item-bodyer"},[_c('p',{staticClass:"intro"},[_vm._v(_vm._s(item.info.speciality))])]):_vm._e()])])}),0)]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }